#slider ion-slide ul li img {
    max-width: 330px;
}

@media (max-width: 1300px) {
    #nav ul.sub_menu {
        left: auto;
        right: 0;
    }
    .content-titles h1 {
        font-size: 30px;
        margin-bottom: 20px;
        line-height: 30px;
    }
    #service h4 {
        font-size: 25px;
        margin-bottom: 20px;
        line-height: 25px;
    }
    #service .service_content_box {
        padding: 20px;
        max-width: 100%;
    }
    #service .img_boxs {
        max-width: 220px;
        margin-top: 10px;
    }
    #service .services_list_block ion-row {
        align-items: initial !important;
    }
    .service_img img {
        height: 100%;
    }
    .service_img {
        height: 100%;
    }
    .section-titles h1 {
        font-size: 30px;
    }
    #curriculum .curriculum_content {
        width: calc(100% + 50px);
    }
}

@media (max-width: 1200px) {
    .container {
        padding: 0px 15px !important;
        width: 100% !important;
        max-width: 100% !important;
    }
    #slider .slider-content-box {
        padding: 0px 50px;
    }
}

@media (max-width: 992px) {
    .pop-modal {
        --height: 80% !important;
        --width: calc(100% - 30px) !important;
    }
    #paymentsuser .modal-dialog {
        padding: 30px 15px;
    }
    #disciple .filter_top_list ul {
        flex-wrap: wrap;
    }
    #disciple .filter_top_list ul li {
        padding: 10px;
    }
    #disciple ion-col {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    #disciple .disciple_filter {
        height: 100vh;
        position: fixed;
        width: 100%;
        left: -100%;
        overflow-y: auto;
        top: 0;
        bottom: 0;
        max-width: 300px;
        background: #fff;
        z-index: 11111;
        border-radius: 0;
        border-top: none !important;
        border-left: none !important;
        padding: 15px !important;
        transition-duration: 700ms;
    }
    #disciple .disciple_filter.open {
        left: 0;
        transition-duration: 700ms;
    }
    #filters_com_overly {
        z-index: 1111;
        height: 100vh;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        display: none;
    }
    #filters_com_overly.open {
        display: block;
        cursor: pointer;
    }
}

@media (max-width: 768px) {
    #paymentsuser .modal-dialog ion-segment-button ion-label {
        font-size: 18px;
    }
    #paymentsuser ion-segment {
        padding-left: 8px;
    }
    #paymentsuser .modal-dialog ion-segment-button {
        max-width: 140px;
        margin: 0px 5px;
        min-height: 40px !important;
    }
    #paymentsuser .paymentsuser_block {
        padding: 20px;
    }
    #paymentsuser .paymentsuser_block ion-row ion-col {
        border-right: none;
        border-bottom: 1px solid #ccc;
        padding: 20px !important;
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    #paymentsuser .paymentsuser_boxs p.contents {
        min-height: auto;
    }
    #paymentsuser .pay_boxs p {
        min-height: auto;
    }
    #paymentsuser .pay_boxs p.r-dnone {
        display: none;
    }
    #paymentsuser .paymentsuser_block ion-row ion-col:last-child {
        border-right: none;
        border-bottom: none;
    }
    #resources .select_lang ion-col {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    #resources .language_box .checkmark {
        border: 2px solid #508398;
    }
    #resources .list_row ion-col {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    #resources .Training_row ion-col {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    #resources .Training_row ion-col.more_buttons {
        text-align: center;
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    #slider .slider-content-box h4 {
        font-size: 30px;
    }
    #slider .slider-content-box p {
        font-size: 19px;
        line-height: 20px;
    }
    .content-title h1 {
        font-size: 20px;
        line-height: 22px;
        margin-bottom: 15px;
    }
    ion-col {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        width: 100% !important;
    }
    .questions_img img {
        width: 100%;
        margin-top: 40px;
    }
    .questions_content {
        text-align: center;
    }
    #questions .questions-block {
        padding: 60px 0 0 0;
    }
    .resource-content {
        text-align: center;
    }
    .content-title ul li a {
        white-space: nowrap !important;
    }
    .content-title ul li p br {
        display: none !important;
    }
    #resource .resource-form {
        padding: 20px 15px;
    }
    #footer .footer-block ul {
        display: block;
        text-align: center;
    }
    #footer .footer-block ul li .social_icons {
        justify-content: center;
    }
    #banner .banner-block h1 {
        font-size: 20px;
        line-height: 20px;
    }
    #banner .banner-block ul li,
    #banner .banner-block ul li a {
        font-size: 14px;
    }
    #banner .banner-block ul li::after {
        width: 1px;
        height: 16px;
    }
    #videos .videos_box h4 {
        text-align: center;
        font-size: 18px;
    }
    #faq #accordion .card-header a {
        font-size: 16px;
        line-height: 18px;
        padding-right: 40px;
    }
    #offsets .widget_header ul {
        margin: 0px -10px;
    }
    #offsets .widget_header ul li {
        padding: 0 10px;
    }
    #offsets .widget_header ul li a {
        font-size: 14px;
        line-height: 14px;
        display: block;
    }
    #offsets .widget_header ul li span {
        font-size: 11px;
        white-space: nowrap !important;
        margin-top: 5px;
    }
    #offsets_navs {
        padding: 13px;
    }
    #slider .slider-contents ul {
        display: block;
        margin: 0;
        text-align: center;
        padding: 0px 30px;
    }
    #slider ion-slide ul li img {
        max-width: 200px;
        margin-bottom: 15px;
    }
    #slider ion-slide ul li .btn.btn-blue {
        font-size: 15px;
        padding: 8px 12px;
    }
    #slider ion-slide ul li .btn.btn-blues {
        font-size: 15px;
        padding: 8px 12px;
    }
    #slider .slider-contents {
        bottom: auto;
        width: 100%;
        height: auto;
        padding: 15px 0;
        top: 50%;
        transform: translate(0%, -50%);
    }
    #slider ion-slide>img {
        width: auto !important;
        height: 350px !important;
        max-width: none !important;
        max-height: none !important;
        object-fit: cover !important;
        object-position: center center !important;
        position: relative;
        display: inherit;
    }
    #services ion-col {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    #services .services_icons img {
        max-height: 60px;
        max-width: 70px;
    }
    #services .services_box {
        padding: 30px;
    }
    #services .services_icons {
        height: 100px;
    }
    #services .services_box h4 {
        font-size: 18px;
    }
    #services .services-block {
        padding: 60px 0 40px 0;
    }
    .table td {
        white-space: nowrap;
    }
    #header .logo-box {
        max-width: 160px;
        width: 100%;
    }
    #slider .slider-content-box h4 {
        font-size: 28px;
        line-height: 28px;
        max-width: 400px;
    }
    #slider .slider-content-box p {
        font-size: 16px;
        line-height: 20px;
        max-width: 400px;
    }
    #curriculum .curriculum_content {
        width: 100%;
        transform: skew(0deg, 0deg);
        padding: 0px !important;
    }
    .curriculum-content {
        padding: 30px 20px;
        transform: skew(0deg, 0deg);
        max-width: 100%;
        text-align: center;
    }
    .curriculum-content p {
        text-align: center;
    }
    #service .service_content_box {
        padding: 30px 20px;
        max-width: 100%;
        text-align: center;
    }
    #service .img_boxs {
        max-width: 260px;
        float: none;
        margin: 0 auto;
        margin-top: 20px;
    }
    #service .services_list_block .service-box:nth-child(2n) ion-col:first-child {
        order: inherit !important;
    }
    #service .services_list_block .service-box:nth-child(2n) ion-col:last-child {
        order: inherit !important;
    }
    #service .services_list_block .service-box:nth-child(2n) .service_content_box {
        text-align: center;
    }
    #access .access_content {
        transform: skew(0deg, 0deg);
        padding: 30px 20px !important;
        max-width: 100%;
        text-align: center;
    }
    #access .access_content_box {
        transform: skew(0deg, 0deg);
        width: 100%;
    }
    #access p {
        text-align: center;
    }
    #access .access_image_boxs {
        padding: 30px 20px !important;
        height: auto;
        text-align: center;
    }
    #resource .resource-form-box h4 {
        margin: 35px 0;
    }
    #steps .steps_boxs h2 {
        font-size: 26px;
        max-width: 180px;
        line-height: 24px;
        min-height: auto;
        margin-bottom: 15px;
    }
    section#slider .slider-box>img {
        height: 320px;
        width: auto;
        max-width: none;
        object-fit: cover;
        object-position: center center;
    }
}

@media (max-width: 639px) {
    #nav ul li {
        margin: 0px 10px;
        padding: 2px 0;
    }
    .menu_content #nav ul li a {
        font-size: 14px;
    }
    #notification {
        position: fixed;
        right: 0;
        top: 100px;
        width: 100%;
        z-index: 2;
        left: 0;
    }
    #stories .stories_box {
        width: 100%;
    }
    #stories .stories_img {
        width: 100%;
        height: auto;
    }
    #stories .stories_img img {
        position: relative;
        top: auto;
        left: auto;
        transform: translate(0%, 0%);
        min-width: auto;
        min-height: auto;
        max-width: 100%;
        width: 100% !important;
    }
    #login .forms_box {
        padding: 20px 15px;
    }
    #login ion-segment-button ion-label {
        font-size: 16px;
        line-height: 20px;
        padding: 10px 0;
        letter-spacing: 0;
    }
    #header .header-top-box ul li a {
        font-size: 14px;
        line-height: 16px;
    }
    #header .header-top-box ul li {
        padding: 10px;
    }
    #header .header-top-box ul li:last-child {
        padding: 10px;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        width: 1%;
    }
    #disciple .form_section {
        padding: 20px 15px 5px 15px;
    }
    #details .content-title ul {
        align-items: center;
        display: block;
    }
    .content-title i.far {
        font-size: 16px;
    }
    #disciple .form_header h4 {
        font-size: 17px;
    }
    #disciple .form_header .favrite i {
        margin-right: 0px;
    }
    #disciple .form_header {
        padding: 0px 15px;
    }
    #disciple .form_header ul li {
        padding: 5px;
    }
    ion-modal {
        --height: 100% !important;
    }
    #question .question_header h2 {
        font-size: 18px;
        line-height: 20px;
    }
    #question .question_header {
        padding: 15px;
    }
    #question .question_section {
        padding: 15px;
    }
    #question .question_id {
        flex: 0 0 40px;
        max-width: 40px;
        min-height: 40px;
    }
    #question .question_id_content h5 {
        font-size: 18px;
    }
    #question .question_section h4 {
        font-size: 16px;
    }
    #question .question-box {
        border-radius: 10px;
    }
    #stories .content-title ul {
        display: block !important;
        text-align: center;
    }
    #stories .content-title ul .btn.btn-blue {
        margin-top: 20px !important;
    }
    #stories .sliders_boxs {
        padding: 0px 15px !important;
    }
    .stories_content {
        padding-bottom: 12px;
        text-align: center;
    }
    #slider .slider-content-box {
        text-align: center;
    }
    .content-title h1 {
        font-size: 28px;
        line-height: 22px;
        margin-bottom: 15px;
    }
    #stories .stories-block {
        padding: 40px 0;
    }
    #header .logo-box {
        max-width: 135px;
        margin-right: 15px;
    }

  
}

@media (max-width: 480px) {
    #paymentsuser .modal-dialog ion-segment-button ion-label {
        font-size: 16px;
    }
    #resources .list_row ion-col {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    #resources .Training_row ion-col {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    .links_box ul {
        display: block;
        margin: 0;
        margin-bottom: 15px;
    }
    .links_box ul li {
        padding: 0;
        margin-bottom: 10px;
    }
    #services ion-col {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    #services .services_icons {
        height: auto;
        margin-bottom: 25px;
    }
    #services .services-block {
        padding: 60px 0 40px 0;
    }
    .menu_ioc #nav ul li {
        margin: 0px 0px;
        padding: 12px 7px;
    }
    .menu_ioc #nav ul li:last-child {
        padding-right: 0;
    }
    .menu_ioc #nav ul li a i {
        font-size: 18px;
    }
    #nav ul li.notification .counts {
        top: 0px;
        right: -3px;
    }
    #nav ul li.user_io span {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        padding: 0;
    }
    #header .logo-box {
        max-width: 170px;
    }
    .menu_content #nav ul li {
        margin: 0;
        padding: 2px 6px;
    }
    .menu_content #nav ul li:last-child {
        padding-right: 0;
    }
    .rdo_box label {
        font-size: 15px;
    }
    p {
        font-size: 15px;
        line-height: 20px;
    }
    .content-title h1 {
        font-size: 18px;
    }
    #disciple .que_ans_list ul li h4 {
        font-size: 16px;
    }
    #details .badge_icon_list ul li {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
    }
    #comments_history ol {
        flex-wrap: wrap;
        margin: 10px -10px !important;
    }
    #comments_history ol li:first-child {
        flex: 0 0 100%;
        max-width: 100%;
        padding-bottom: 5px;
    }
    #banner .banner-block ul {
        margin: 0 -10px;
        flex-wrap: wrap;
    }
    #banner .banner-block ul li {
        padding: 0 10px;
    }
    #banner .banner-block ul li,
    #banner .banner-block ul li a {
        font-size: 13px;
    }
    #banner .banner-block ul li::after {
        top: 1px;
    }
    #banner .banner-block h1 {
        font-size: 18px;
        line-height: 18px;
    }
    #banner .banner-block .que_video a,
    #banner .banner-block .que_video a i {
        font-size: 22px;
    }
    #disciple .form_header .favrite i {
        font-size: 18px;
    }
    #disciple .form_header .badge_icon {
        width: 40px;
        height: 40px;
    }
    label,
    ion-label {
        font-size: 15px;
    }
    #disciple .nav_box ul {
        margin: 0px -10px;
        margin-bottom: 15px;
        justify-content: center;
    }
    #disciple .nav_box ul li {
        padding: 0 10px;
    }
    #disciple .nav_box ul li a {
        font-size: 20px;
        width: 35px;
        height: 35px;
        line-height: 34px;
    }
    #disciple .chkmark {
        font-size: 15px !important;
    }
    .form-control {
        font-size: 15px !important;
    }
    .modal-headers h4 {
        font-size: 18px;
    }
    #schedule .tabs_forms h4 a {
        font-size: 18px;
    }
    .btn.btn-blue {
        padding: 8px 20px;
    }
    .form-group ion-label {
        font-size: 15px;
    }
    #login .login-box {
        border-radius: 10px;
    }
    .section-titles h1 {
        font-size: 26px;
    }
    .content-titles h1 {
        font-size: 26px;
        line-height: 26px;
    }
    #service h4 {
        font-size: 20px;
        line-height: 20px;
    }
    #access .download_boxs h4 {
        font-size: 17px;
    }
    #slider .slider-content-box h4 {
        font-size: 20px;
        line-height: 20px;
        width: 100%;
        max-width: 100%;
    }
    #slider .slider-content-box p {
        font-size: 14px;
        line-height: 17px;
        width: 100%;
        max-width: 100%;
    }
    #slider .button_box {
        margin-top: 20px;
    }
}

@media (max-width: 359px) {
    #header .logo-box {
        max-width: 150px;
    }
    #disciple .filter_top_list ul li.search {
        flex: 0 0 100%;
        max-width: 100%;
    }
    #disciple .disciple_filter {
        max-width: 280px;
    }
    #header .logo-box {
        max-width: 135px;
    }
}