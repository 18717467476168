/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

// @import "~@ng-select/ng-select/themes/material.theme.css";
@import "~@ng-select/ng-select/themes/default.theme.css";


@import "~@ionic/angular/css/core.css";
@import '@ionic/angular/css/ionic-swiper.css';
/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import 'swiper/scss';
@import '@ionic/angular/css/ionic-swiper';
ion-input{
    // --highlight-color-focused :#ffffff00 !important
}
 ion-select, ion-textarea{
    --highlight-color-focused : #0000 !important;
    --highlight-color-valid: #0000 !important;
    --highlight-color-invalid: #0000 !important;
    --highlight-color: #0000 !important;
    --cursor: auto !important;
}
// ion-input{
//     --highlight-color-focused: #ffffff00 !important;
//     --highlight-color-valid: #ffffff00 !important;
//     --highlight-color-invalid: #ffffff00 !important;
//     --highlight-color: #ffffff00 !important;
//     --border-color: #ffffff00 !important
// }
// ion-textarea{
//     --highlight-color-focused: #ffffff00 !important;
//     --highlight-color-valid: #ffffff00 !important;
//     --highlight-color-invalid: #ffffff00 !important;
//     --border-color: #ffffff00 !important
// }
.ng2-tag-input.ng2-tag-input--focused{
    border-bottom: 1px solid #ffffff00 !important;
}
.monthview-datetable td {
    cursor: pointer;
    text-align: center;
    height: 44px;
    align-items: center;
    vertical-align: middle !important;
}
.table-bordered th{
    background-color: #528499 !important;
    color: #fff !important;
}
.event-detail-container{
    display: none !important;
}
// .monthview-primary-with-event{
//     background-color: #a2005c !important;
//     color: #fff;
// }
.ng2-tag-input.ng2-tag-input--focused {
    border-bottom: 0px solid #2196F3;
 }
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.scrollable-element {
    scrollbar-color: #508398 white;
  }
body {
    margin: 0;
    padding: 0;
    font-family: 'Calibri';
    font-weight: 400;
    -webkit-overflow-scrolling: touch;
}

body,
* {
    font-family: 'Calibri';
 
}

:focus {
    outline: none;
}

ul,
li,
ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
    -webkit-transition: 0.5s;
    color: #494949;
    transition: 0.5s;
}

input[type="button"],
input[type="submit"] {
    text-decoration: none;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

a:hover {
    color: #0072ca;
    text-decoration: none;
    outline: none;
}

a:focus {
    text-decoration: none;
    outline: none;
}

a img,
img {
    border: none;
    vertical-align: middle;
    max-width: 100%;
    width: auto
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    margin: 0px;
    padding: 0px;
}

:before,
 :after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

ul,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.cart-modal .modal-wrapper {
    width: 80% !important;
    height: 50% !important;
}

.add-on-modal .modal-wrapper {
    width: 90% !important;
    height: 70% !important;
    border-radius: 15px;
    padding: 10px !important;
}

.add-review-modal .modal-wrapper {
    width: 90% !important;
    height: 30% !important;
}

ion-app {
    // margin-top: env(safe-area-inset-top);
    // margin-bottom: env(safe-area-inset-bottom)
    margin-bottom: 0px;
}

ion-title {
    padding: 0px;
}

ion-header {
    display: contents !important;
}

ion-content {
    --background: #fff !important;
    background: #fff !important;
}

.alert-wrapper {
    --width: 90% !important;
    width: 90% !important;
    --max-width: 90% !important;
    //  --background: #e5e5e5;
}

.alert-message {
    height: 80% !important;
}

.alert-button {
    text-transform: capitalize !important;
}

.alert-button span {
    text-decoration: none !important;
}

ion-toolbar {
    // box-shadow: 0 0 0 0;
    --background: #ee6706 !important;
    border: none;
    color: #fff !important;
}

.popover_setting .popover-content {
    --min-width: 90% !important;
    // --min-height: 150px !important;
    --ion-background-color: white;
    --ion-text-color: #FF0606;
    background-color: white;
}

ion-tab-bar {
    background: white !important;
    --background: white !important;
}

ion-radio {
    --color-checked: #FF0606;
    --color: #FF0606;
}

.img {
    width: 20px;
    height: auto;
    margin-right: 5px;
}

.back {
    width: 30px;
    height: auto;
    left: 25px;
    top: 20px;
    position: absolute;
    z-index: 1111;
}

.corner-img {
    position: absolute;
    width: 40%;
    right: 0 !important;
    //  z-index: 11;
}

.bold {
    font-family: "Poppins-Bold";
    color: #000;
    font-weight: bold;
}

.setting-icon {
    height: 18px;
}

ion-list-header {
    background: #e3e3e3;
}

.chek_box {
    position: relative;
    padding-left: 33px;
    display: inline-block;
}

.chek_box label {
    margin: 0 !important;
    padding: 0px !important;
    font-weight: 400;
    color: #505050;
    font-size: 17px;
    line-height: 26px;
}

.chek_box input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 22;
    opacity: 0;
}

.chek_box .checkmak {
    position: absolute;
    top: 3px;
    left: 0;
    border: 2px solid #DBDBDB;
    width: 22px;
    height: 22px;
    border-radius: 3px;
}

.chek_box .checkmak::after {
    content: "";
    display: none;
    left: 6px;
    top: 2px;
    width: 6px;
    height: 12px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
}

.chek_box input:checked~.checkmak::after {
    display: block;
}

.chek_box input:checked~.checkmak {
    background-color: #508398;
    border-color: #508398;
}

.chek_box .checkmakc {
    position: absolute;
    top: 3px;
    left: 0;
    border: 2px solid #DBDBDB;
    width: 22px;
    height: 22px;
    border-radius: 3px;
}

.chek_box .checkmakc::after {
    content: "";
    display: none;
    left: 6px;
    top: 2px;
    width: 6px;
    height: 12px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
}

.chek_box input:checked~.checkmakc::after {
    display: block;
}

.chek_box input:checked~.checkmakc {
    background-color: #ac2730;
    border-color: #ac2730;
}

.chek_box .checkmaka {
    position: absolute;
    top: 3px;
    left: 0;
    border: 2px solid #DBDBDB;
    width: 22px;
    height: 22px;
    border-radius: 3px;
}

.chek_box .checkmaka::after {
    content: "";
    display: none;
    left: 6px;
    top: 2px;
    width: 6px;
    height: 12px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
}

.chek_box input:checked~.checkmaka::after {
    display: block;
}
.chek_box input:checked~.checkmaka {
  background-color: #508398;
  border-color: #508398;
}

.rdoblock {
    display: flex;
    align-items: center;
    margin: 0 -15px;
}

.rdoblock li {
    padding: 0 15px;
}

.rdo_box {
    position: relative;
    padding-left: 33px;
    display: inline-block;
    cursor: pointer;
}

.rdo_box label {
    margin: 0 !important;
    padding: 0px !important;
    color: #000;
    line-height: 20px;
}

.rdo_box input {
    position: absolute;
    top: 0px;
    left: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 22;
    opacity: 0;
}

.rdo_box .checkmak {
    position: absolute;
    top: 3px;
    left: 0;
    border: 2px solid #c8c8c8;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.rdo_box .checkmak::after {
    display: none;
    content: "";
    background: #6ab950;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.rdo_box input:checked~.checkmak::after {
    display: block;
}

ion-toolbar {
    background: #ef7635;
    --background: #ef7635;
}

#header ion-row {
    justify-content: space-between;
}

ion-header .logo_box ion-img {
    width: 100%;
    max-width: 150px;
    margin: 0px auto;
}

ion-header .search_box {
    display: flex;
    align-items: center;
    background: transparent;
    padding: 2px 0px;
    border-radius: 6px;
    margin-top: 25px;
    width: 28px;
    float: right;
}

#header .search_block {
    flex: 0 0 300px;
    width: 300px;
}

#header .search_btn {
    padding: 0px;
    filter: inherit;
    width: 20px !important;
    cursor: pointer;
}

#header .close_btn {
    width: 20px !important;
    margin: 0px 7px;
}

ion-header .menubars {
    flex: 0 0 30px;
    width: 30px;
    color: #fff;
    font-size: 30px !important;
}

#header .open .search_box {
    background: #fff;
    padding: 2px 15px;
    width: 100%;
    float: none;
}

#header .open .search_btn {
    filter: invert(100%);
}

.header-box {
    position: relative;
}

.page_title {
    text-align: center;
    margin: auto;
}

#header2 .search_box {
    margin-top: 0px;
}

ion-header .back_icon {
    flex: 0 0 30px;
    height: 30px;
}

#header #header2 {
    height: 69px;
    line-height: 46px;
}

ion-header .menubars {
    flex: 0 0 24px;
    width: 24px;
    font-size: 24px !important;
    height: 30px;
}

ion-header ion-row {
    justify-content: space-between;
}

.list_col {
    flex: 0 0 33.333333%;
}

.list_inner_box {
    background-color: #204c55;
    border-radius: 5px;
    padding: 15px;
    margin: 2px;
    height: 100%;
}

.list_inner_box .white.content {
    color: #fff;
    display: block;
    line-height: 18px;
    margin-bottom: 6px;
}

.prise_boxs {
    align-items: center;
}

.prise_boxs span {
    color: #fff;
    font-size: 15px;
    padding-top: 20px;
}

.prise_boxs ion-input {
    --padding: 0px !important;
    border-bottom: 2px solid #fff;
    padding: 0px 0px !important;
    --padding-top: 5px !important;
    --padding-bottom: 5px !important;
}

.prise_boxs .item-input {
    margin-left: 0;
    margin-right: 0;
    margin: 0px !important;
    --padding: 0px !important;
}

.prise_boxs ion-button {
    margin-top: 20px;
    margin-left: 10px;
}

.btn-orange {
    text-align: center !important;
    font-size: 14px;
    display: inline-block;
}

.text-center {
    text-align: center;
}

.list_block .white.content {
    color: #fff;
    display: block;
    line-height: 15px;
    margin-bottom: 10px;
}

.w-100 {
    width: 100% !important;
}

.page_block {
    display: table;
    width: 100%;
    max-width: 350px;
    text-align: center;
    margin: 0 auto;
    height: 100vh;
    padding: 15px;
}

.page_block_login {
    display: table;
    width: 100%;
    max-width: 350px;
    text-align: center;
    margin: 0 auto;
    height: 50vh;
    padding: 0px;
}

.page_box {
    display: table-cell;
    vertical-align: middle;
}

.page_block .logo {
    max-width: 210px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 15px;
}

.page_block .content {
    color: #000;
    line-height: 20px !important;
    display: block !important;
    text-align: center;
}

.page_block .d-inline {
    display: inline-block !important;
}

.list_title {
    text-align: center;
}

.page_block input {
    appearance: textfield !important;
}

.page_block input {
    appearance: textfield !important;
}

.page_title ion-label {
    font-weight: 700 !important;
    letter-spacing: 1px;
}

.search_boxss {
    max-width: 340px;
    margin: 0 auto;
}

.login-modal .modal-wrapper {
    width: 100% !important;
    height: 470px !important;
    border: 1px solid #fff !important;
    max-width: 375px !important;
    border-radius: 15px !important;
    padding: 0px !important;
    margin: 0 auto !important;
}

.login-modal ion-content {
    --overflow: hidden !important;
}

.login-modal .page_block_login {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 30px;
    height: auto !important;
    max-width: 100%;
}

.login-modal .page_block_login .page_box {
    width: 100%;
}

.login-modal .page_block_login .page_box .logo {
    max-width: 170px;
    width: 100%;
    margin: 0 auto;
}

.register-modal .modal-wrapper {
    width: 100% !important;
    height: 625px !important;
    border: 1px solid #fff !important;
    max-width: 400px !important;
    border-radius: 15px !important;
    padding: 0px !important;
    margin: 0 auto !important;
}

.register-modal .page_block_login {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 20px;
    height: auto !important;
    max-width: 100%;
}

.register-modal .page_block_login .page_box {
    width: 100%;
}

.register-modal .page_block_login .page_box .logo {
    max-width: 170px;
    width: 100%;
    margin: 0 auto;
}

.register-modal ion-radio-group ion-item {
    display: flex;
    align-items: center;
}

.register-modal ion-radio-group ion-item ion-radio {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    margin-top: 5px;
}

.register-modal ion-radio-group ion-item ion-label {
    position: relative;
    width: 100%;
    display: block;
    margin: 0px 15px;
    padding: 0px;
}

.register-modal ion-row {
    margin-top: 0px !important;
}

.white.content.highlight {
    background: #ef7635;
    color: #fff;
    padding: 7px 10px 7px 15px;
    display: inline-block;
    margin-bottom: 10px;
    border-radius: 0px 15px 15px 0px;
    position: relative;
    left: -15px;
}

.list_inner_box .white.content p {
    display: inline;
}

#contact_forms .section {
    padding: 20px;
    background: #fff;
}

#contact_forms form {
    margin-top: -80px;
    background-color: #f1f1f1;
    padding: 15px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 5px 5px 5px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
}

#contact_forms .form-group {
    min-height: auto;
    margin-bottom: 10px;
}

#contact_forms .form-control {
    border: 1px solid #606062;
    border-radius: 5px;
    color: #000;
    background: #fff;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 400;
}

#page_banner ion-label {
    font-size: 30px !important;
    display: block !important;
    font-weight: 700;
    text-align: left !important;
    color: #fff !important;
    padding: 100px 0px;
    text-transform: uppercase;
}

#page_banner .page_banner_block {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.headings {
    padding: 0px !important;
    margin: 0px !important;
    background: #fff !important;
}

#page_content .content {
    padding: 0px !important;
    margin: 0px !important;
    margin-bottom: 15px !important;
}

#page_content .page_content_box {
    padding: 60px 0px 45px 0px;
    background: #f3f3f3;
}

.swiper-pagination {
    display: none !important;
}

span#icons_click {
    height: 50px;
    width: 50px;
    position: fixed;
    bottom: 40px;
    overflow: hidden;
    right: 90px;
    z-index: 111;
    cursor: pointer;
}

.contact-form {
    position: fixed;
    background: #fff;
    right: 30px;
    bottom: 0%;
    max-height: calc(100vh - 150px);
    padding: 0px;
    overflow: hidden;
    overflow-y: auto;
    transition-duration: 700ms;
    border-radius: 10px;
    width: 100%;
    max-width: 360px;
    z-index: -1;
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.08);
    opacity: 0;
}

.contact-form .form-control {
    --ion-background-color: transparent;
    border-bottom: 1px solid #000;
    color: #000;
    font-size: 16px;
}

#closes {
    display: none;
}

#contact_forms.open .contact-form {
    bottom: 100px;
    opacity: 1;
    transition-duration: 700ms;
    z-index: 1111111;
}

#contact_forms.open #closes {
    display: block;
}

#contact_forms.open #contact_icon {
    display: none;
}

#contact_forms .heading {
    padding: 20px;
    background: #ef7635;
    padding-bottom: 80px;
}

#contact_forms .heading ion-label {
    font-size: 20px !important;
    line-height: 20px;
    display: block;
    color: white;
    font-family: "Tahoma" !important;
    font-weight: 700 !important;
    letter-spacing: 0.8px;
}

#contact_forms .heading ion-label span {
    color: white !important;
    font-size: 14px !important;
    font-weight: 400;
    display: block;
    margin-top: 7px;
    letter-spacing: 0.2px;
}

#about .content {
    text-align: justify;
}

#footer .content {
    text-align: justify;
}

//***********************************Css from web ****************************************************
#curriculum .curriculum_content {
    background: #fff;
    height: 100%;
    transform: skew(-10deg, 0deg);
    background-clip: content-box;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    width: calc(100% + 150px);
    margin-left: 0;
    position: relative;
    z-index: 2;
    padding: 0;
    display: flex;
    align-items: center;
    box-shadow: 3px 3px 4px #ccc;
}

.curriculum-content {
    padding: 80px;
    transform: skew(10deg, 0deg);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Calibri-Bold';
    margin: 0px;
    padding: 0px;
}

p {
    color: #707070;
    font-family: "Calibri";
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 15px;
}

.align-center {
    align-items: center !important;
}

#download .download-block {
    padding: 60px 0;
}

#download .button_box ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px -15px;
    margin-top: 10px;
}

#download .button_box ul li {
    padding: 15px;
}

#download .button_box ul li a {
    width: 100%;
    max-width: 150px;
    display: block;
}

#download .button_box.text-right {
    margin-top: 35px;
}

#download .button_box ul li {
    list-style: none;
}

.btn.btn-blues {
    border: 1px solid #508398 !important;
    box-shadow: none !important;
    background: #508398 !important;
    color: #fff;
    font-weight: 700;
    font-family: 'Calibri-Bold';
    font-size: 15px;
    padding: 10px 20px;
    text-transform: uppercase;
    border-radius: 5px;
    text-decoration: none;
}

.btn.btn-blue {
    border: 1px solid #ab192d !important;
    box-shadow: none !important;
    background: #ab192d !important;
    color: #fff;
    font-weight: 700;
    font-family: 'Calibri-Bold';
    font-size: 15px;
    padding: 10px 20px;
    text-transform: uppercase;
    border-radius: 5px;
    text-decoration: none;
}

ion-button.btn.btn-blue {
    padding: 0;
    --background: #ab192d !important;
    --box-shadow: none;
    height: 45px;
    font-family: 'Calibri-Bold';
}

.text-right {
    text-align: right !important;
}

#questions .questions-block {
    padding: 60px 0;
    background-color: #D9E0E2;
}

.order-2 {
    -ms-flex-order: 2;
    order: 2;
}

.order-1 {
    -ms-flex-order: 1;
    order: 1;
}

#resource .resource-block {
    padding: 60px 0;
}

#resource .resource-form {
    background: #efeffe !important;
    padding: 15px;
    border-radius: 10px;
}

#resource .resource-form .form-control {
    padding: 0px 15px !important;
    border: 1px solid #e7e7eb !important;
    font-family: "Calibri";
    font-size: 19px !important;
}
#resource .resource-form-box h4 {
    color: #508398;
    font-family: 'Calibri-Bold';
    font-size: 30px;
    text-align: center;
    margin-bottom: 35px;
}

#resource #prayer .resource-box {
    padding-top: 10px;
}

#resource ion-row {
    align-items: center;
}

#resource .resource-form .form-group {
    margin-bottom: 15px;
}

#resource .resource-form ion-row {
    margin: 0px -10px !important;
}

#resource .resource-form ion-row ion-col {
    padding: 0px 10px !important;
}

#stories .stories-block {
    padding: 60px 0;
    background-color: #D9E0E2;
}

#stories .stories-box {
    margin: 0 -15px;
}

#stories .stories_box {
    display: block;
    width: calc(100% - 30px);
    margin: 0 auto;
}

#stories .stories_img {
    width: 100%;
    height: 250px;
    overflow: hidden;
    border-radius: 5px;
    position: relative;
    background-color: #fff;
}

#stories .stories_img img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    width: auto !important;
}

#stories .stories_content h4 {
    margin: 15px 0;
    color: #707070;
    font-size: 18px;
}

#stories .stories_content p {
    color: #707070;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    min-height: 60px;
}

#stories .button_box {
    margin-top: 20px;
}

.form-control {
    background-color: #fff !important;
    border-radius: 5px !important;
    height: auto !important;
    padding: 0px 15px !important;
    border: 1px solid #e7e7eb !important;
    font-family: "Calibri";
    font-size: 18px !important;
    color: #707070 !important;
    box-shadow: none !important;
    font-weight: 400;
    width: 100%;
    --padding-top: 0;
    --padding-end: 0;
    --padding-bottom: 0;
    --padding-start: 0;
}

.form-group {
    margin-bottom: 20px;
    color: #000 !important;
}

ion-col {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

ion-row {
    margin-left: -15px;
    margin-right: -15px;
}

.small-text p {
    margin-top: 10px;
    margin: 0;
    margin-top: 10px;
    color: #55899f;
}

.w-auto {
    width: auto !important;
}

.content-title ul {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0px -15px;
    margin-bottom: 30px;
}

.content-title ul li {
    padding: 0px 15px;
    list-style: none;
}

.content-title ul li p {
    margin: 0;
}

.stories_content {
    padding-bottom: 12px;
    text-align: left;
}

#footer .footer-block {
    padding: 10px 0;
    background: #508398;
}

#footer .footer-block ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px -15px;
}

#footer .footer-block ul li {
    padding: 6px 15px;
    list-style: none;
    margin: 0px !important;
}

#footer .footer-block ul li a {
    color: #fff !important;
    font-size: 17px;
    text-decoration: none;
}

#footer .footer-block ul li .social_icons {
    display: flex;
    align-items: center;
    margin: 0px -10px;
}

#footer .footer-block ul li .social_icons li {
    padding: 6px 10px;
}

#footer .copyright-block {
    text-align: center;
    padding: 15px 0;
    background: #fff;
}

#footer .copyright-block p {
    margin: 0;
    font-size: 17px;
    color: #508398;
}

#header .header-top-block {
    background: #508398;
}

#header .header-top-box ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0px -15px;
}

#header .header-top-box ul li {
    padding: 10px 15px;
    list-style: none;
}

#header .header-top-box ul li a {
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    display: block;
    text-decoration: none;
}

#header .header-block {
    padding: 10px 0px;
    z-index: 1111 !important;
    width: 100% !important;
    background: #fff;
}

#header .header-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#header .logo-box {
    max-width: 210px;
    width: 100%;
    display: block;
}

#header .logo-box img {
    width: 100% !important;
    height: auto !important;
}

#header .header-block.isStuck {
    position: fixed !important;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
}

.bx-wrapper .bx-controls-direction a.disabled {
    display: block !important;
}

#nav ul li {
    display: inline-block;
    position: relative;
    margin: 0px 10px;
    padding: 12px 10px;
    vertical-align: middle;
    list-style: none;
}

#nav ul li a {
    font-size: 16px;
    color: #508398;
    text-transform: capitalize;
    text-decoration: none;
}

#nav ul li:first-child {
    margin-left: 0px;
    padding-left: 0px;
}

#nav ul li:last-child {
    margin-right: 0px;
    padding-right: 0px;
}

#nav ul li a i {
    color: #508398;
    font-size: 23px;
    display: block;
}

#nav ul li.notification .counts {
    background: #ab192d;
    border: none;
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    line-height: 20px;
    font-size: 10px;
    text-align: center;
    border-radius: 50%;
    top: 7px;
    right: -3px;
    color: #fff !important;
}

#nav ul li.user_io span {
    background: #508398;
    color: #fff;
    display: block;
    width: 36px;
    height: 36px;
    padding: 0 5px;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 50%;
    text-align: center;
    line-height: 36px;
    font-weight: 700;
}

#nav ul.sub_menu {
    position: absolute;
    top: 51px;
    background: #fff;
    box-shadow: 0 0 4px #ccc;
    margin: 0px;
    display: none;
    border-radius: 5px;
    padding: 5px 0;
    left: 0;
}

#nav ul.sub_menu li {
    padding: 4px 12px !important;
    margin: 0px !important;
}

#nav ul li:hover ul.sub_menu {
    display: block;
}

#google_translate_element {
    position: relative;
    width: 200px;
}

#google_translate_element .goog-te-combo {
    margin: 0px !important;
    position: absolute;
    background: #fff !important;
    border: 1px solid #508398;
    padding: 5px 10px;
    border-radius: 5px;
    display: block !important;
    min-width: 150px;
    color: #508398;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
}

.goog-te-banner-frame.skiptranslate {
    display: none !important;
}

#banner ion-row {
    margin: 0 !important;
}

#banner ion-col {
    padding: 0 !important;
}

textarea.form-control {
    height: 150px !important;
    padding-block: 11px !important;
}

#banner .banner-block {
    padding: 15px 0px;
    width: 100%;
    position: relative;
    background: #508398 !important;
}

#banner .banner-block h1 {
    position: relative;
    text-transform: capitalize;
    font-size: 30px;
    color: #fff;
    line-height: 35px;
    margin-bottom: 10px;
}

#banner .banner-block ul {
    display: flex;
    align-items: center;
    margin: 0 -15px;
}

#banner .banner-block ul li {
    padding: 0 15px;
    position: relative;
}

// #banner .banner-block ul li::after {
//     content: "";
//     display: block;
//     position: absolute;
//     width: 1px;
//     height: 20px;
//     background: #fff;
//     position: absolute;
//     top: 3px;
//     left: 0;
//     transform: rotate(20deg);
// }


#banner .banner-block ul li::after {
    content: "\f054";
    display: block;
    position: absolute;
    color: #fff;
    position: absolute;
    top: 2px;
    left: -5px;
    font-family: 'fontawesome';
}

#banner .banner-block ul li:first-child::after {
    display: none;
}

#banner .banner-block ul li,
#banner .banner-block ul li a {
    color: #fff !important;
    font-size: 18px;
    font-style: italic;
}

#banner .banner-block .banner-box {
    position: relative;
}

#banner .banner-block .que_video {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(0%, -50%);
}

#banner .banner-block .que_video a,
#banner .banner-block .que_video a i {
    color: #fff;
    font-size: 26px;
    display: block;
    font-weight: 900;
}

#disciple .disciple-block {
    padding: 60px 0;
}

#disciple .table th,
#disciple .table td {
    text-align: left;
}

#disciple .table th:first-child,
#disciple .table td:first-child {
    text-align: center;
}

#disciple .disciple_filter {
    border: 1px solid #DBDBDB !important;
    padding: 20px;
    border-radius: 10px;
}

#disciple .disciple_filter ul li ul {
    padding-left: 30px;
}

#disciple .disciple_filter ul li {
    padding-top: 10px;
}
#disciple .disciple_filter ul li ion-radio {
    margin-top: 0px !important;
    position: relative;
    top: 4px;
    margin-right: 5px !important;
}
#disciple .disciple_filter ul li ion-label {
   font-size: 16px !important;
   color: #000;
   font-weight: 500;
}

#disciple .disciple_filter .content-title h1 {
    margin-bottom: 8px;
}

#disciple .rdo_box .checkmak::after {
    background: #508398;
}

#disciple .rdo_box input:checked~.checkmak {
    border: 2px solid #508398;
}

#disciple td a {
    font-size: 16px;
    color: #000 !important;
    text-decoration: none !important;
}
#disciple td a:hover{
    color: #508398 !important;
    text-decoration: underline !important;
}

#disciple td a.btn-blue {
    color: #fff !important;
    font-size: 15px;
}

#disciple td .far.fa-star {
    display: block;
    margin: 0 auto;
    width: 18px;
    font-size: 18px;
    color: #000;
    cursor: pointer;
}

#disciple td .far.fa-star.active {
    font-weight: 900;
    color: #FFDD00;
}

#disciple .disciple_filter .rdo_box {
    display: block;
}

#disciple .disciple_filter .rdo_box label {
    display: block;
}

#disciple .counts {
    float: right;
    font-size: 15px;
}

#disciple .form_box {
    border: 1px solid #DBDBDB !important;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 30px;
}

#disciple .form_header {
    border-bottom: 1px solid #DBDBDB;
    background: #FCFCFC;
    padding: 0px 20px;
}

#disciple .form_header ul {
    display: flex;
    justify-content: space-between;
    margin: 0px -10px;
    align-items: center;
}

#disciple .form_header ul li {
    padding: 10px;
    position: relative;
}

#disciple .form_header .user_img {
    display: block;
    width: 90px;
    height: 90px;
    background: #ccc;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    padding: 1px;
}

#disciple .form_header h4 {
    font-size: 22px;
    color: #8e9c6b;
}

#disciple .form_header .favrite i {
    font-size: 23px;
    margin-right: 30px;
    cursor: pointer;
    color: #000;
}

#disciple .form_header .favrite i.active {
    font-weight: 900;
    color: #FFDD00;
}

#disciple .form_header .badge_icon span {
    font-weight: 700;
    display: block;
    position: absolute;
    top: 0;
    text-align: center;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 50px;
    font-size: 20px;
}

#disciple .form_section {
    padding: 30px 30px 15px 30px;
}

#disciple .chkmark {
    background-color: #fff;
    border: 1px solid #e7e7eb !important;
    border-radius: 5px !important;
    height: auto !important;
    padding: 12px 15px !important;
    font-size: 18px !important;
    color: #707070 !important;
    box-shadow: none !important;
    display: block;
}

#disciple .chkmark.active {
    background-color: #82AE3F !important;
    color: #fff !important;
}

#disciple .organization_list {
    border-bottom: 1px solid #707070;
    margin-bottom: 15px;
    padding-bottom: 5px;
}

#disciple .organization_list:last-child {
    border-bottom: none;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

#disciple .organization_list ul {
    display: flex;
}

#disciple .organization_list ul li:first-child {
    flex: 0 0 150px;
    max-width: 150px;
    width: 150px;
}

#disciple .organization_list ul li p {
    color: #000;
    margin: 0;
}

#disciple .organization_list ul li {
    padding-bottom: 10px;
}

#disciple .history_list-block p {
    color: #000;
    margin-bottom: 5px;
    font-family: 'Calibri';
}

#disciple .history_list {
    border-bottom: 1px solid #707070;
    margin-bottom: 15px;
    padding-bottom: 5px;
}

#disciple .history_list:last-child {
    border-bottom: none;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

#disciple .history_list ul {
    display: flex;
}

#disciple .history_list ul li:first-child {
    flex: 0 0 165px;
    max-width: 165px;
    width: 165px;
}

#disciple .history_list ul li p {
    color: #000;
    margin: 0;
    font-family: 'Calibri';
}

#disciple .history_list ul li {
    padding-bottom: 10px;
}

#disciple .history_list ul li a {
    color: #508398 !important;
    font-style: italic;
}

#disciple .nav_box ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0px -15px;
    margin-bottom: 15px;
}

#disciple .nav_box ul li {
    padding: 0 15px;
}

#disciple .nav_box ul li a {
    border: 1px solid #508398 !important;
    box-shadow: none !important;
    background: #508398 !important;
    color: #fff;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    font-size: 22px;
    padding: 0;
    text-transform: uppercase;
    border-radius: 5px;
    display: block;
    width: 35px;
    text-align: center;
    height: 35px;
}

#disciple .disciple-block#details {
    padding-bottom: 40px;
}

#offsets_navs {
    box-shadow: 0 0 4px #eee;
    margin-top: 26px;
    background: #eee;
    padding: 15px;
}

#offsets .widget_header ul {
    margin: 0px -15px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#offsets .widget_header ul li {
    list-style: none;
    text-align: center;
    display: block;
    padding: 0 15px;
}

#offsets .widget_header ul li a {
    color: #000;
    font-size: 18px;
    text-decoration: none !important;
    font-family: 'Calibri-Bold';
}

#offsets .widget_header ul li span {
    font-size: 12px;
    font-weight: 400;
}

#offsets .offsets-block.isStuck {
    top: 52px !important;
    width: 100%;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
    background-color: #eee;
}

#resources .resources-block {
    padding: 60px 0 10px 0;
}

#resources .resources-box {
    margin-bottom: 50px;
}

.table-responsive {
    border: 1px solid #DBDBDB !important;
    border-radius: 10px;
    margin: 0 !important;
}

.table {
    border: none !important;
    margin: 0 !important;
}

.table th,
.table td {
    vertical-align: middle;
    padding: 10px;
    text-align: center;
    border: none !important;
    font-size: 16px;
    color: #000;
}

.table th {
    background: #FCFCFC;
}

.table td {
    border-top: 1px solid #DBDBDB !important;
}

.table td .fa-book {
    color: #82AE3F;
    font-size: 50px;
}

.table td .btn.btn-blue.linkss {
    padding: 6px 15px;
}

.table td .btn.btn-gray.linkss {
    padding: 6px 15px;
}

.table tr.disabled {
    opacity: 0.3;
}

.table td span {
    display: block;
    font-size: 14px;
    opacity: 0.8;
}

.input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

.select2.select2-container {
    width: 100% !important;
    display: block !important;
}

.selection {
    display: block;
    width: 100% !important;
}

.select2-selection {
    background-color: #fff !important;
    border: 1px solid #B7B7B7 !important;
    border-radius: 5px !important;
    height: auto !important;
    padding: 13px 15px !important;
    font-size: 16px !important;
    color: #707070 !important;
    box-shadow: none !important;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    display: block !important;
    width: 100% !important;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 0;
    padding-right: 0px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px !important;
    color: #707070 !important;
    box-shadow: none !important;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    display: block !important;
    line-height: normal !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 50px !important;
}

.filter_block {
    margin-bottom: 40px;
}

.table tr.hiddens {
    display: none !important;
}
 div.hiddens {
    display: none !important;
}

.content-title h1 {
    color: #508398;
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 20px;
}

.container {
    max-width: 1300px !important;
    width: 100% !important;
    margin: 0 auto !important;
    padding: 0 100px !important;
}

#resources .video_box {
    display: block;
    background-image: url('assets/images/cuisine-loader.gif') !important;
    background-size: 20% !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    min-height: 50px !important;
}

#resources .video_box video {
    width: 250px !important;
    display: block;
}

.content-title i.far {
    color: #ccc !important;
    font-size: 18px;
    margin-left: 5px;
    font-weight: 900;
}

#disciple #accordion .card-header a {
    display: block;
    width: 100%;
    background-color: #FCFCFC;
    text-align: left;
    font-size: 16px;
    color: #86926b;
    position: relative;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    line-height: 24px;
    padding: 0;
    padding-right: 10px;
    text-decoration: none !important;
}

#disciple #accordion .card-header .card-link.collapsed::after {
    content: " \f067";
    font-family: 'fontawesome';
}

#disciple #accordion .card-header .card-link::after {
    content: " \f068";
    font-family: 'fontawesome';
    display: block;
    color: #86926b;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    font-size: 11px;
    font-weight: normal;
}

#disciple #accordion .card-body.filter_content {
    padding: 15px;
    padding-top: 6px;
}

#disciple #accordion .card-header {
    padding: 10px 13px;
    border: none !important;
    border-radius: 0;
}

#disciple #accordion .card {
    margin-top: 10px;
}

#disciple .col-md-4 {
    position: relative;
}

#disciple .disciple_filter {
    height: 100%;
    position: absolute;
    width: calc(100% - 30px);
    left: 15px;
    overflow-y: auto;
}

#disciple .filter_top_list {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}

#disciple .disciple-box {
    margin-top: 20px;
}

#disciple .filter_top_list ul {
    display: flex;
    align-items: center;
    margin: 0px -10px;
    justify-content: center;
}

#disciple .filter_top_list ul li {
    padding: 0px 10px;
}

#disciple .filter_top_list ul li.search {
    flex: 0 0 350px;
    max-width: 350px;
}

#disciple .filter_top_list ul li a.btn.btn-blue {
    text-transform: inherit;
    padding: 6px 10px;
    font-size: 15px;
}

#disciple .filter_top_list ul li .form-control {
    padding: 0px 15px !important;
    border-radius: 6px 0 0 6px !important;
}

#disciple .filter_top_list ul li a.btn.btn-blue i {
    margin-right: 5px;
}

#disciple .button_box {
    text-align: center;
    margin-top: 30px;
}

.filter_block .input-group>.form-control {
    border-radius: 6px 0 0 6px !important;
}

.content-title i.far {
    color: #ccc !important;
    font-size: 18px;
    margin-left: 5px;
    font-weight: 900;
}

#details .content-title ul {
    align-items: center;
}

#details .badge_icon_list ul {
    display: flex;
    margin: 0px -10px;
    flex-wrap: wrap;
}

#details .badge_icon_list ul li {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 10px;
    opacity: 0.6;
    list-style: none;
}
#details .badge_icon_list ul li.active {
  opacity: 1;
//   border-radius: 10px;
//   border: 1px solid #73AD21;
//   margin-right: 3px;

}

#details .badge_icon_list ul li p {
    font-size: 18px;
    margin: 0px;
    text-align: center;
    color: #508398;
}



#details .badge_icon_list .icons_imgs {
    width: 100%;
    height: calc(100% - 30px);
}

#details .checkbxo ol {
    display: flex;
    margin: 0px -10px;
    align-items: center;
    margin-bottom: 15px;
}

#details .checkbxo ol li {
    padding: 0 10px;
}

#details .checkbxo label {
    margin: 0px;
}

.rdo_box {
    position: relative;
    padding-left: 33px;
    display: inline-block;
    cursor: pointer;
}

.rdo_box input {
    position: absolute;
    top: 0px;
    left: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 22;
    opacity: 0;
}

.rdo_box .checkmak {
    position: absolute;
    top: 3px;
    left: 0;
    border: 2px solid #c8c8c8;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

ul li {
    list-style: none;
    text-decoration: none;
}

.modal .close {
    position: fixed;
    z-index: 1111111;
    background: #508398 !important;
    width: 40px;
    height: 40px;
    opacity: 1 !important;
    color: #fff;
    cursor: pointer;
    font-size: 20px;
    // border-radius: 0;
    border-radius: 0 3px 0 0;
    right: 0;
    top: 0;
}



#organization .modal-dialog {
    max-width: 500px;
    margin: 50px auto;
    width: calc(100% - 30px);
}

#organization .modal-headers {
    padding: 15px;
    border-bottom: 1px solid #B7B7B7 !important;
}

#organization .modal-headers h4 {
    color: #8e9c6b;
    font-size: 20px;
}

#organization .butons_box {
    margin-top: 30px;
    text-align: right;
}

#schedule .schedule_form input {
    color: #000;
    width: 100%;
    display: block;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid #B7B7B7 !important;
    padding: 10px 0;
}

#schedule .tabs_list ul {
    display: flex !important;
    margin: 0px;
    align-items: center;
}

#schedule .tabs_list ul li {
    margin: 0px !important;
    padding: 0;
    border: none !important;
    background: transparent !important;
}

#schedule .tabs_list ul li a {
    border-radius: 0px !important;
    width: auto;
    padding: 8px 10px;
    color: #000;
    font-weight: 400;
    background: #fff;
}

#schedule .tabs_list ul li a {
    width: auto;
    padding: 8px 10px;
    color: #000;
    font-weight: 400;
    background: #fff;
    border-radius: 5px !important;
    display: block;
    margin-right: 10px;
}

#schedule .tabs_list ul li {
    position: relative;
}

#schedule .tabs_list ul li ion-radio {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    display: block;
    z-index: 2;
}

#schedule .tabs_list ul li ion-radio.radio-checked~a {
    color: #fff !important;
    background: #508398 !important;
}

#schedule .tabs_forms ul {
    position: relative;
    margin-top: 15px;
}

#schedule .tabs_forms ul li p {
    margin: 0px;
    padding-bottom: 2px;
}

#schedule .tabs_forms h4 a {
    color: #508398 !important;
    font-weight: 700;
    font-size: 20px;
    margin-top: 15px;
    display: inline-block;
}

#schedule .modal-body {
    padding-left: 50px;
}

#schedule .tabs_forms ul li.icons {
    position: absolute;
    left: -35px;
    top: 5px;
}

#schedule .tabs_forms ul.inputs li.icons {
    top: 13px;
}

#schedule .tabs_forms ul li.icons i {
    color: #707070;
    font-size: 19px;
}

#schedule .butons_box {
    margin-top: 30px;
    text-align: center;
}

#schedule .schedule_form .butons_box input.btn {
    display: inline-block !important;
    width: 100px !important;
    color: #fff;
}

#nav ul li {
    display: inline-block;
    position: relative;
    margin: 0px 10px;
    padding: 12px 10px;
    vertical-align: middle;
}

#nav ul li a {
    font-size: 17px;
    color: #508398;
    text-transform: uppercase;
}

#nav ul li:first-child {
    margin-left: 0px;
    padding-left: 0px;
}

#nav ul li:last-child {
    margin-right: 0px;
    padding-right: 0px;
}

#nav ul li a i {
    color: #508398;
    font-size: 23px;
    display: block;
}

#nav ul li.notification .counts {
    background: #ab192d;
    border: none;
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    line-height: 20px;
    font-size: 13px;
    text-align: center;
    border-radius: 50%;
    top: 7px;
    right: -3px;
    color: #fff !important;
    
}

#nav ul li.user_io span {
    background: #508398;
    color: #fff;
    display: block;
    width: 36px;
    height: 36px;
    padding: 0 5px;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 50%;
    text-align: center;
    line-height: 36px;
    font-weight: 700;
}

#nav ul.sub_menu {
	position: absolute;
	top: 51px;
	background: #fff;
	box-shadow: 0 0 4px #ccc;
	margin: 0px;
	display: none;
	border-radius: 5px;
	padding: 5px 0;
	left: 0;
	z-index: 2;
	width: 167px;
}

#nav ul.sub_menu li {
    padding: 4px 12px !important;
    margin: 0px !important;
}

#nav ul li:hover ul.sub_menu {
    display: block;
}

#notification {
    position: absolute;
    background: #fff;
    right: -69px;
    top: 46px;
    border: 1px solid #000;
    width: 500px;
    display: none;
    z-index: 2;
    
}

#nav ul li:hover #notification {
    display: block;
}

#notification .notification_header ul {
    margin: 0px -15px !important;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px !important;
}

#notification .notification_header .count_boxs .count {
    background: #ab192d;
    display: block;
    color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    font-size: 14px;
    line-height: 29px;
    padding: 0 5px;
    margin-right: 5px;
}

#notification .notification_header>ul>li {
    padding: 15px !important;
    margin: 0px !important;
}

#notification .count_boxs {
    display: flex;
    align-items: center;
}

#notification .notification_header li p,
#notification .notification_header li p a {
    margin: 0px;
    padding: 0;
    font-weight: 600;
    font-size: 16px;
    color: #000 !important;
}

#notification .notification_header>ul>li .nav {
    display: flex;
    align-items: center;
    margin: 0px -10px !important;
    padding: 0px !important;
}

#notification .notification_header>ul>li .nav li {
    padding: 0 10px !important;
    margin: 0px !important;
}

#notification .notification_header>ul>li .nav li a {
    background-color: #fff;
    border: 1px solid #508398;
    color: #508398;
    padding: 5px;
    display: block;
    width: 90px;
    text-align: center;
    border-radius: 5px;
    font-size: 16px;
    line-height: 23px;
}

#notification .notification_header>ul>li .nav li a.active {
    background-color: #508398;
    border: 1px solid #508398;
    color: #fff;
}

#notification .notification_box h4 {
    color: #fff;
    font-size: 14px;
    background: #508398;
    font-weight: 400;
    padding: 5px 15px;
}

#notification .notification_content {
    // background: #D9E0E2;
    background: #fff;
    padding: 5px 15px;
}
#notification .unread .notification_content {
    background: #e2edf1;
}

#notification .notification_content p {
    margin: 0px !important;
    color: #000;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
}

#notification .notification_content p a {
    margin: 0px !important;
    color: #508398;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
}

#notification .notification_content ul {
    display: flex;
    align-items: center;
    margin: 0px -5px !important;
    padding: 0px !important;
}

#notification .notification_content ul li {
    padding: 0 5px !important;
    margin: 0px !important;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    color: #000;
}

#notification .notification_content ul li p {
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #000;
}

#notification .notification_footer a {
    color: #508398;
    font-size: 16px;
    font-weight: 600;
    padding: 15px !important;
    display: inline-block;
    line-height: 24px;
}

#notification .unread .notification_content {
    position: relative;
    padding-right: 50px;
}

#notification .unread .notification_content::after {
    content: "";
    display: block;
    background: #508398;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0%, -50%);
}

#notification .notification_section {
    position: relative;
    max-height: calc(100vh - 252px);
    overflow: hidden;
    overflow-y: auto;
    min-height: 87px;
}



#services .services-block {
    padding: 60px 0;
}

#services .services_box {
    background: #FCFCFC;
    height: 100%;
    width: 100%;
    text-align: center;
    box-shadow: 0px 0px 5px #DBDBDB;
    border-radius: 10px;
    padding: 50px 30px;
}

#services .services-block ion-col {
    margin-bottom: 30px;
}

#services .services_icons {
    height: 140px;
}

#services .services_icons img {
    width: auto;
    display: block;
    max-height: 100px;
    margin: 0 auto;
    max-width: 150px;
}

#services .services_icons img.h {
    display: none;
}

#services .services_box h4 {
    color: #505050;
    font-size: 26px;
}

#services .services_box:hover {
    background: #508398;
}

#services .services_box:hover h4 {
    color: #fff;
}

#services .services_box:hover .services_icons img.d {
    display: none;
}

#services .services_box:hover .services_icons img.h {
    display: block;
}



#faq #accordion .card {
    display: block !important;
    width: 100% !important;
    box-shadow: none !important;
    border: none;
    overflow: hidden;
    border-radius: 7px;
    margin-bottom: 15px;
    padding: 0px;
    border: 1px solid #DBDBDB;
}

#faq #accordion .card-header {
    background-color: #fff;
    border-radius: 0px !important;
    padding: 0px !important;
    border: none !important;
    box-shadow: none !important;
}

#faq #accordion .card-header a {
    display: block;
    width: 100%;
    background-color: #FCFCFC;
    text-align: left;
    font-size: 20px;
    color: #86926b;
    position: relative;
    line-height: 24px;
    padding: 20px;
    padding-right: 40px;
    font-family: 'Calibri-Bold';
}

#faq .card-body.faq_content {
    text-align: justify;
    padding: 20px;
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #DBDBDB;
}

#faq #accordion {
    overflow: hidden;
    background: transparent;
    position: relative;
    height: auto;
    max-height: none !important;
}

#faq .card-body.faq_content h4 {
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 15px;
}

#faq .card-body.faq_content ul li {
    list-style-type: disc;
    font-family: 'SegoeUI';
    color: #707070;
    font-size: 16px;
    margin-left: 30px;
}

#faq .card-body.faq_content ul li p {
    margin-bottom: 5px;
}

#faq .card-body.faq_content ul {
    margin-bottom: 15px;
    display: block;
}

#faq .card-body.faq_content ul:last-child {
    margin-bottom: 0px;
}

#videos {
    padding-bottom: 30px;
}

#videos .videos-box {
    padding-top: 30px;
}

#videos .video_box video {
    width: 100% !important;
    height: auto !important;
    border-radius: 20px;
    box-shadow: 0px 0px #ccc;
}

#videos .videos_box {
    margin-bottom: 30px;
}

#videos .videos_box h4 {
    color: #505050;
    font-size: 20px;
    margin-top: 15px;
}

#videos .button_box {
    text-align: center;
    margin: 10px 0 30px 0;
}

ion-select {
    max-width: 100%;
    --padding-start: 0px;
    background-color: #fff !important;
    border-radius: 5px !important;
    height: auto !important;
    color: #707070 !important;
    box-shadow: none !important;
    font-weight: 400;
    width: 100%;
    padding: 0px 15px !important;
    border: 1px solid #e7e7eb !important;
    font-family: "Calibri";
    font-size: 19px !important;
}

body {
    position: relative !important;
    top: auto !important;
}

#slider ion-slide {
    display: flex !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    text-align: left !important;
    font-size: inherit !important;
}

#slider ion-slide img {
    width: 100% !important;
    height: auto !important;
}

#slider .slider-content-block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#slider .slider-content-box {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translate(0%, -50%);
}

#slider .slider-content-box h4 {
    color: #fff;
    font-size: 70px;
    margin-bottom: 15px;
    text-transform: uppercase;
    max-width: 50%;
    line-height: 70px;
}
#pagebanner .slider-content-box h4 {
    color: #fff;
    font-size: 70px;
    margin-bottom: 15px;
    text-transform: uppercase;
    max-width: 50%;
    line-height: 70px;
}
#slider .slider-content-box p {
    color: #fff;
    font-size: 16px;
    line-height: 23px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    max-width: 50%;
}

#slider .button_box {
    margin-top: 30px;
}

#slider .slider-contents {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: auto;
    padding: 30px 0;
}

#slider .slider-contents ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px -15px;
}

#slider .slider-contents ul li {
    padding: 0px 15px;
}



#login .login-block {
    padding: 60px 0;
}

#login .login-box {
    max-width: 600px;
    width: 100%;
    background: #FCFCFC;
    margin: 0 auto;
    border: 1px solid #DBDBDB;
    border-radius: 20px;
}

#login .forms_box {
    padding: 40px;
}

#login ion-segment-button {
    // max-width: 170px;
    padding: 0px !important;
    --padding-top: 5px;
    --padding-end: 0;
    --padding-bottom: 5px;
    --padding-start: 0;
}

#login ion-segment-button ion-label {
    color: #505050 !important;
    font-weight: 700;
    font-size: 20px;
    width: 100% !important;
    line-height: 20px;
    padding: 20px 0;
    display: block;
    font-family: 'Calibri-Bold';
}

#login ion-segment-button.segment-button-checked ion-label {
    color: #508398;
}

#login .tabs_list {
    border-bottom: 1px solid #DBDBDB;
}

.form-group ion-label {
    font-weight: 400;
    color: #505050;
    font-size: 18px;
    margin: 0px;
    margin-bottom: 0px;
    padding: 0px;
    margin-bottom: 5px;
    font-family: "Calibri";
    display: block;
}

.form-group ion-label sup {
    color: red !important;
    font-size: 15px;
    top: -6px !important;
}

ion-label {
    font-weight: 400;
    color: #505050;
    font-size: 18px;
    font-family: 'Calibri';
}

.links_box ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px -15px;
    margin-bottom: 20px;
}

.links_box ul li {
    padding: 0px 15px;
}

.link {
    color: #6fa4bd !important;
    font-size: 18px;
    font-weight: 400;
    text-decoration: underline;

}

.link_info {
    margin-top: 35px;
    text-align: center;
}

.link_info p {
    font-weight: 400;
    font-family: 'Calibri';
}

.link_info p a {
    display: inline-block !important;
    color: #508398;
    font-weight: 700;
    font-size: 16px;
    font-family: 'Calibri';
    padding: 0px !important;
    margin-left: 5px;
}

#slider .slider-box {
    position: relative;
}

#slider .slider-box .slider_nav {
    position: absolute;
    left: 15px;
    top: 50%;
    z-index: 11;
    transform: translate(0%, -50%);
    width: 20px;
}

#slider .slider-box .slider_nav.right {
    left: auto;
    right: 15px;
}

#slider .slider-box .slider_nav img {
    width: 100%;
    cursor: pointer;
}

.d-block {
    display: block !important;
}

.input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control,
.input-group>.form-control-plaintext {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
}

.input-group-append,
.input-group-prepend {
    display: -ms-flexbox;
    display: flex;
    margin-left: -1px;
}

.table-responsive {
    border: 1px solid #DBDBDB !important;
    border-radius: 10px;
    margin: 0 !important;
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    border-spacing: 0;
    border-collapse: collapse;
}


#question .question-block {
    padding: 60px 0;
}

#question .question-box {
    max-width: 600px;
    width: 100%;
    background: #FCFCFC;
    margin: 0 auto;
    border: 1px solid #DBDBDB;
    border-radius: 20px;
}

#question .question_header {
    padding: 20px 30px;
    border-bottom: 1px solid #DBDBDB;
}

#question .question_section {
    padding: 30px;
}

#question .question_header h2 {
    color: #505050;
    font-size: 22px;
    text-transform: capitalize;
    line-height: 30px;
}

#question .question_section h4 {
    color: #505050;
    font-size: 18px;
    margin-bottom: 15px;
}

#question .question_box {
    position: relative;
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    display: flex;
    margin-bottom: 15px;
    overflow: hidden;
}

#question .question_box input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 22;
}

#question .question_content p {
    margin: 0;
    padding: 12px 15px;
}

#question .question_id {
    background: #A7C1CB;
    flex: 0 0 50px;
    max-width: 50px;
    text-align: center;
    position: relative;
    min-height: 50px;
}

#question .question_id_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#question .question_id_content h5 {
    color: #505050;
    font-size: 20px;
}

#question .question_box input:checked~.question_id {
    background: #508398;
}

#question .question_box input:checked~.question_id h5 {
    color: #fff;
}

#disciple .que_ans_list ul {
    padding-bottom: 20px;
}

#disciple .que_ans_list ul:last-child {
    padding-bottom: 0px;
}

#disciple .que_ans_list ul li h4 {
    font-size: 18px;
    font-weight: 700;
    display: block;
    margin-bottom: 10px;
}

#disciple .que_ans_list ul li p {
    line-height: 20px;
    margin-bottom: 10px;
    padding-left: 15px;
    font-size: 16px;
}

#disciple .que_ans_list ul li i {
    position: relative;
    display: inline-block;
    font-weight: 700;
    font-size: 16px;
    color: #000;
    padding-left: 35px;
}

#disciple .que_ans_list ul li i::after {
    content: "";
    display: block;
    width: 15px;
    height: 2px;
    background: #000;
    left: 10px;
    top: 50%;
    position: absolute;
}

#disciple .que_ans_list ul li p i {
    padding-left: 25px;
}

#disciple .que_ans_list ul li p i::after {
    left: 0px;
}

#share .butons_box {
    margin-top: 30px;
    text-align: right;
}

video {
    vertical-align: baseline;
    width: 100% !important;
    height: auto !important;
}

.modal-body {
    padding: 15px;
}

.modal-headers {
    padding: 15px;
    border-bottom: 1px solid #B7B7B7 !important;
}

.modal-headers h4 {
    color: #8e9c6b;
    font-size: 20px;
}

.modal-wrapper {
    border-radius: 20px 20px 0 0 !important;
}

.video-modal {
    --height: 350px;
    --width: 650px;
    align-items: center !important;
}


.pop-modal {
    --height: 80% !important;
    --width: 70% !important;
    align-items: center !important;
}

.share-modal {
	// --height: 250px;
	--height: 45%;
	align-items: center !important;
	background: rgba(0, 0, 0, 0.7);
	--width: 400px;
	padding: 0;
	--background: transparent !important;
}
.app-share-modal {
	--height: 280px;
	align-items: center !important;
	background: rgba(0, 0, 0, 0.7);
	--width: 400px;
	padding: 0;
	--background: transparent !important;
}
.change-success-modal {
	--height: 85%;
	align-items: center !important;
	background: rgba(0, 0, 0, 0.7);
	--width: 48%;
	padding: 0;
	--background: transparent !important;
}
.chat-modal {
	--height: 80%;
	align-items: center !important;
	background: rgba(0, 0, 0, 0.7);
	--width: 35%;
	padding: 0;
	--background: transparent !important;
}
.PayPal-Sel-modal {
	--height: 300px;
	align-items: center !important;
	background: rgba(0, 0, 0, 0.7);
	--width: 500px;
	padding: 0;
	--background: transparent !important;
}
.recommand-modal {
	--height: 550px;
	align-items: center !important;
	background: rgba(0, 0, 0, 0.7);
	--width: 550px;
	padding: 0;
	--background: transparent !important;
}
.payment-success-modal {
	--height: 250px;
	align-items: center !important;
	background: rgba(0, 0, 0, 0.7);
	--width: 400px;
	padding: 0;
    border-radius: 50px !important;
	--background: transparent !important;
}
.payment-failed-modal {
	--height: 250px;
	align-items: center !important;
	background: rgba(0, 0, 0, 0.7);
	--width: 400px;
	padding: 0;
    border-radius: 50px !important;
	--background: transparent !important;
}

#share .download_boxs {
	padding: 50px 30px 20px 30px !important;
	text-align: center;
}

.share-modal .modal-body {
    padding: 0;
}
.share-modal div#share {
    border-radius: 20px !important;
    padding: 0;
    background: #fff;
}
.share-modal ion-content {
    --background: transparent !important;
    background: transparent !important;
}


.msg-modal {
	--height: 200px;
	align-items: center !important;
	background: rgba(0, 0, 0, 0.7);
	--width: 400px;
	padding: 0;
	--background: transparent !important;
}

#msg .download_boxs {
	padding: 50px 30px 20px 30px !important;
	text-align: center;
}

.msg-modal .modal-body {
    padding: 0;
}
.msg-modal div#msg {
    border-radius: 20px !important;
    padding: 0;
    background: #fff;
}
.msg-modal ion-content {
    --background: transparent !important;
    background: transparent !important;
}


#faqs {
    padding: 60px 0 20px 0;
}

.fa-beat {
    -webkit-animation-name: fa-beat;
    animation-name: fa-beat;
    -webkit-animation-delay: var(--fa-animation-delay, 0);
    animation-delay: var(--fa-animation-delay, 0);
    -webkit-animation-direction: var(--fa-animation-direction, normal);
    animation-direction: var(--fa-animation-direction, normal);
    -webkit-animation-duration: var(--fa-animation-duration, 1s);
    animation-duration: var(--fa-animation-duration, 1s);
    -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    animation-iteration-count: var(--fa-animation-iteration-count, infinite);
    -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
    animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

@media (prefers-reduced-motion:reduce) {
    .fa-beat,
    .fa-beat-fade,
    .fa-bounce,
    .fa-fade,
    .fa-flip,
    .fa-pulse,
    .fa-shake,
    .fa-spin,
    .fa-spin-pulse {
        -webkit-animation-delay: -1ms;
        animation-delay: -1ms;
        -webkit-animation-duration: 1ms;
        animation-duration: 1ms;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
        transition-delay: 0s;
        transition-duration: 0s
    }
}

@-webkit-keyframes fa-beat {
    0%,
    90% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    45% {
        -webkit-transform: scale(var(--fa-beat-scale, 1.25));
        transform: scale(var(--fa-beat-scale, 1.25))
    }
}

@keyframes fa-beat {
    0%,
    90% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    45% {
        -webkit-transform: scale(var(--fa-beat-scale, 1.25));
        transform: scale(var(--fa-beat-scale, 1.25))
    }
}

@-webkit-keyframes fa-bounce {
    0% {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0)
    }
    10% {
        -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
        transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0)
    }
    30% {
        -webkit-transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
        transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em))
    }
    50% {
        -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
        transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0)
    }
    57% {
        -webkit-transform: scale(1) translateY(var(--fa-bounce-rebound, -.125em));
        transform: scale(1) translateY(var(--fa-bounce-rebound, -.125em))
    }
    64% {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0)
    }
    to {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0)
    }
}

@keyframes fa-bounce {
    0% {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0)
    }
    10% {
        -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
        transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0)
    }
    30% {
        -webkit-transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
        transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em))
    }
    50% {
        -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
        transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0)
    }
    57% {
        -webkit-transform: scale(1) translateY(var(--fa-bounce-rebound, -.125em));
        transform: scale(1) translateY(var(--fa-bounce-rebound, -.125em))
    }
    64% {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0)
    }
    to {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0)
    }
}

@-webkit-keyframes fa-fade {
    50% {
        opacity: var(--fa-fade-opacity, .4)
    }
}

@keyframes fa-fade {
    50% {
        opacity: var(--fa-fade-opacity, .4)
    }
}

@-webkit-keyframes fa-beat-fade {
    0%,
    to {
        opacity: var(--fa-beat-fade-opacity, .4);
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    50% {
        opacity: 1;
        -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
        transform: scale(var(--fa-beat-fade-scale, 1.125))
    }
}

@keyframes fa-beat-fade {
    0%,
    to {
        opacity: var(--fa-beat-fade-opacity, .4);
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    50% {
        opacity: 1;
        -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
        transform: scale(var(--fa-beat-fade-scale, 1.125))
    }
}

@-webkit-keyframes fa-flip {
    50% {
        -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
        transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg))
    }
}

@keyframes fa-flip {
    50% {
        -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
        transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg))
    }
}

@-webkit-keyframes fa-shake {
    0% {
        -webkit-transform: rotate(-15deg);
        transform: rotate(-15deg)
    }
    4% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg)
    }
    8%,
    24% {
        -webkit-transform: rotate(-18deg);
        transform: rotate(-18deg)
    }
    12%,
    28% {
        -webkit-transform: rotate(18deg);
        transform: rotate(18deg)
    }
    16% {
        -webkit-transform: rotate(-22deg);
        transform: rotate(-22deg)
    }
    20% {
        -webkit-transform: rotate(22deg);
        transform: rotate(22deg)
    }
    32% {
        -webkit-transform: rotate(-12deg);
        transform: rotate(-12deg)
    }
    36% {
        -webkit-transform: rotate(12deg);
        transform: rotate(12deg)
    }
    40%,
    to {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
}

@keyframes fa-shake {
    0% {
        -webkit-transform: rotate(-15deg);
        transform: rotate(-15deg)
    }
    4% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg)
    }
    8%,
    24% {
        -webkit-transform: rotate(-18deg);
        transform: rotate(-18deg)
    }
    12%,
    28% {
        -webkit-transform: rotate(18deg);
        transform: rotate(18deg)
    }
    16% {
        -webkit-transform: rotate(-22deg);
        transform: rotate(-22deg)
    }
    20% {
        -webkit-transform: rotate(22deg);
        transform: rotate(22deg)
    }
    32% {
        -webkit-transform: rotate(-12deg);
        transform: rotate(-12deg)
    }
    36% {
        -webkit-transform: rotate(12deg);
        transform: rotate(12deg)
    }
    40%,
    to {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
}

@-webkit-keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

.section-titles h1 {
    display: inline-block;
    color: #000;
    font-weight: normal;
    text-transform: uppercase;
    font-family: 'Calibri';
    letter-spacing: 0.5px;
    font-size: 40px;
    border-bottom: 2px solid #508398;
    padding: 5px 10px;
}

.section-titles {
    text-align: center;
    margin-bottom: 30px;
}

.content-titles h1 {
    font-family: "Calibri";
    color: #1a1a1a;
    text-transform: uppercase;
    font-size: 44px;
    margin-bottom: 30px;
    line-height: 42px;
    font-weight: normal;
}

.content-titles h1 span {
    color: #508398;
    font-family: 'Calibri-Bold';
}

#curriculum ion-row,
#curriculum ion-row ion-col {
    margin: 0px !important;
    padding: 0px !important;
}

.curriculum-content {
    padding: 0px 80px;
    transform: skew(10deg, 0deg);
    max-width: 720px;
}

.curriculum-content p {
    color: #707070;
    font-family: 'Calibri';
    font-size: 17px;
    line-height: 20px;
    text-align: justify;
}

.curriculum-content p b {
    font-family: 'Calibri-Bold';
}

.curriculum-content .button_boxs {
    margin-top: 40px;
}

#service .service-block {
    padding-top: 30px;
}

#service .services_list_block {
    border-top: 2px solid #d0d0d0;
    border-bottom: 2px solid #d0d0d0;
}

#service h4 {
    font-family: "Calibri";
    color: #1a1a1a;
    font-size: 34px;
    margin-bottom: 30px;
    line-height: 32px;
    font-weight: normal;
}

#service h4 span {
    color: #508398;
    font-family: 'Calibri-Bold';
}

#service p {
    color: #707070;
    font-family: 'Calibri';
    font-size: 17px;
    line-height: 20px;
}

#service .img_boxs {
    width: 100%;
    max-width: 270px;
    margin: 0;
    margin-top: 30px;
    float: right;
}

#service .service_content_box {
    text-align: right;
    padding: 30px;
    max-width: 550px;
    margin: 0 auto;
}

#service .service_img img {
    width: 100% !important;
    display: block;
}

#service .services_list_block ion-row {
    margin: 0px !important;
    padding: 0px !important;
    align-items: center !important;
}

#service .services_list_block ion-row ion-col {
    padding: 0px !important;
    margin: 0px !important;
}

#service .services_list_block .service-box:nth-child(2n) ion-col:first-child {
    order: 2;
}

#service .services_list_block .service-box:nth-child(2n) ion-col:last-child {
    order: 1;
}

#service .services_list_block .service-box:nth-child(2n) .service_content_box {
    text-align: justify;
}

#steps .steps-block {
    padding: 60px 0 30px 0;
    border-bottom: 2px solid #e0e0e0;
}

#steps ion-col {
    margin-bottom: 30px;
}

#steps .steps_boxs {
    background: #6391a3;
    height: 100%;
    text-align: center;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0px 0px 15px #aaa;
    margin: 0 auto;
    max-width: 300px;
}

#steps .steps_boxs h2 {
    color: #fff;
    font-family: "Calibri-Bold";
    font-size: 30px;
    max-width: 208px;
    margin: 0 auto;
    line-height: 30px;
    min-height: 130px;
}

#steps .steps_boxs h2 span {
    font-family: "Calibri";
    font-weight: normal;
}

#steps .steps_boxs p {
    color: #fff;
    font-family: 'Calibri';
    font-size: 16px;
    margin: 0;
    line-height: 18px;
}

#access .access-block {
    padding-top: 20px;
}

#access ion-row,
#access ion-row ion-col {
    margin: 0px !important;
    padding: 0px !important;
}

#access .access_content_box {
    background-color: #fff;
    background-clip: border-box;
    height: 100%;
    transform: skew(-10deg, 0deg);
    background-clip: content-box;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    width: calc(100% + 150px);
    margin-left: 0;
    position: relative;
    z-index: 2;
    padding: 0;
    box-shadow: 3px 3px 4px #ccc;
    display: block;
}

#access .access_content {
    padding: 0px 80px;
    transform: skew(10deg, 0deg);
    max-width: 720px;
    padding-top: 110px;
}

#access .access_image_boxs {
    padding: 30px;
    padding-left: 100px;
    background: #508398;
    height: 100%;
    padding-top: 110px;
}

#access .access_image img {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 620px;
}

#access .button_boxs {
    margin-top: 30px;
}

#access .download_boxs ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px -15px;
    margin-top: 0px;
    margin-top: 10px;
}

#access .download_boxs ul li {
    list-style: none;
    padding: 15px;
}

#access .download_boxs ul li a {
    width: 100%;
    max-width: 150px;
    display: block;
}

#access .download_boxs h4 {
    color: #fff;
    font-family: 'Calibri';
    text-transform: uppercase;
    font-weight: normal;
    text-align: center;
    margin: 0;
}

#access .download_boxs {
    margin-top: 40px;
}

#access .access_content {
    padding-bottom: 45px;
}

#access p {
    text-align: justify;
}

#service #QUESTIONS .services_list_block {
    border-bottom: none;
}

#service #QUESTIONS .service_content_box {
    max-width: 600px;
    margin: 0 auto;
}

.disciple-box .form-group label {
    display: block;
    // margin-bottom: 11px;
    font-size: 16px;
    color: black;
}

#stories .sliders_boxs {
    padding: 0 !important;
}

// ion-loading.custom-loading .loading-wrapper {
//     width: 100px !important;
//     height: 100px !important;
//     margin: 0px auto !important;
//     background-color: transparent !important;
//     background-image: url('assets/images/cuisine-loader.gif') !important;
//     background-size: 100% 100% !important;
//     background-repeat: no-repeat !important;
//     background-position: center center !important;
//     padding: 0px !important;
//     border-radius: 0.8rem;
//     box-shadow: 0 0px 0px rgba(0, 0, 0, 0.4);
// }
ion-loading.custom-loading {
    .loading-wrapper {
        color: #0000;
        background: #0000 url("./assets/images/cuisine-loader.gif") no-repeat center;
        background-size: 100px 100px;
        /* to change dimension of background */
        padding: 50px;
        /* padding-bottom: 36px; */
        border-radius: 0.8rem;
        box-shadow: 0 0px 0px rgba(0, 0, 0, 0.4);
    }
}
#paymentsuser .modal-dialog {
    background: #ccc;
    padding: 30px;
    display: block;
}

#paymentsuser .modal-dialog ion-segment-button {
    background: #508398 !important;
    font-weight: 700 !important;
    color: #fff !important;
    max-width: 200px;
    width: 100%;
    margin: 0px 15px;
    border-radius: 15px 15px 0 0px;
    opacity: 0.5;
    --background: #508398 !important;
    --indicator-color: transparent !important;
}

#paymentsuser .modal-dialog ion-segment-button.segment-button-checked {
    opacity: 1;
}

#paymentsuser .modal-dialog ion-segment-button ion-label {
    color: #fff !important;
    text-transform: capitalize !important;
    font-size: 22px;
    letter-spacing: 0.2px;
}

#paymentsuser .paymentsuser_block {
    background: #fff;
    padding: 30px;
    border-radius: 20px;
    display: block;
}

#paymentsuser .paymentsuser_block ion-row {
    border: 1px solid #ccc;
    margin: 0px !important;
    padding: 0px;
    border-radius: 10px;
}

#paymentsuser .paymentsuser_block ion-row ion-col {
    padding: 30px !important;
    border-right: 1px solid #ccc;
}

#paymentsuser .paymentsuser_block ion-row ion-col:last-child {
    border-right: none;
}

#paymentsuser .paymentsuser_boxs h4 {
    font-size: 22px;
    display: block;
    margin: 0;
    color: #000;
    margin-bottom: 15px;
}

#paymentsuser .paymentsuser_boxs p {
    margin-bottom: 15px;
    display: block;
    font-size: 17px;
}

#paymentsuser .paymentsuser_boxs h6 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 13px;
    color: #000 !important;
}

#paymentsuser .paymentsuser_boxs p.contents {
    min-height: 80px;
}

#paymentsuser .pay_boxs p {
    min-height: 30px;
}

#paymentsuser .pay_boxs {
    margin-bottom: 35px;
}

#paymentsuser .paymentsuser_boxs .page_list p {
    margin-bottom: 10px;
}

#paymentsuser .paymentsuser_boxs .page_list ul {
    display: block;
    list-style-type: none !important;
    padding-left: 20px;
}

#paymentsuser ion-segment {
    display: flex !important;
    justify-content: flex-start;
    padding-left: 20px;
}

#resources .language_box {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
}

#resources .language_box ion-radio {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 11;
    left: 0;
    top: 0;
    border: none;
    opacity: 0;
    cursor: pointer;
}

#resources .language_box .checkmark {
    border: 4px solid #508398;
    width: 100%;
    height: 100%;
    background: #eee;
    padding: 30px;
    text-align: center;
    border-radius: 15px;
}

#resources .language_box ion-radio.radio-checked~.checkmark {
    border-color: #ac202f !important;
}

#resources .language_box .checkmark img {
    display: block;
    width: auto;
    height: 40px;
    margin: 0 auto;
}

#resources .language_box .checkmark p {
    margin: 0px !important;
    padding: 0px !important;
    color: #000 !important;
    font-weight: 700;
    margin-top: 20px !important;
    text-transform: capitalize;
    font-size: 18px;
    letter-spacing: 0.8px;
}

#resources .select_lang ion-col {
    margin-bottom: 30px;
}

#resources .select_lang {
    padding-bottom: 20px;
}

#resources .list_btn .btn.btn-blues {
    padding: 0px !important;
    border: 1px solid #508398 !important;
    box-shadow: none !important;
    --background: #508398 !important;
    background: #508398 !important;
    width: 100%;
    display: block;
    font-size: 18px;
    --padding-start: 12px;
    --padding-end: 12px;
    --padding-top: 12px;
    --padding-bottom: 12px;
    height: auto !important;
}

#resources .list_row ion-col {
    margin-bottom: 30px;
}

#resources .list_row {
    margin-bottom: -30px;
}

#resources .Training_lists_box ion-button.btn.btn-blue {
    height: 40px;
}

#resources .Training_lists_box {
    border: 2px solid #d1cfcf;
    // padding: 30px;
    text-align: center;
    height: 100%;
    box-shadow: 0px 0px 4px #ccc;
    border-radius: 10px;
}

#resources .Training_lists_box h4 {
    // margin-bottom: 20px;
    padding-block: 15px !important;
}

#resources .Training_row ion-col {
    margin-bottom: 12px;
}

// #resources .Training_lists_box h4 {
//     margin-bottom: 20px;
//     min-height: 50px;
// }

#resources .Training_row ion-col.hiddens {
    display: none;
}

#resources .Training_row ion-col.disabled.hiddens {
    display: none;
}

.Training_row ion-col.disabled .Training_lists_box h4,
#resources .Training_row ion-col.disabled .Training_lists_box .btn_boxs {
    opacity: 0.5;
    pointer-events: none;
}

#resources .Training_row ion-col.more_buttons {
    text-align: center;
}

#resources .Training_lists {
    margin-bottom: -30px;
}

.btn.btn-blue.round {
    padding: 0px !important;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 22px;
    line-height: 51px;
    border: none !important;
}

#paymentsuser .paymentsuser_boxs .page_list ul li {
    list-style-type: disc;
    color: #707070;
    font-family: "Calibri";
}

.Training_lists .checkmark h4 {
    min-height: 50px;
    margin: 0;
    margin-bottom: 15px !important;
}

.Training_lists .checkmark img {
    display: block !important;
    margin: 0 auto !important;
    width: 100% !important;
    max-width: 70px !important;
    height: auto !important;
    margin-bottom: 15px !important;
}




#share .download_boxs ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px -15px;
    margin-top: 0px;
    margin-top: 10px;
}

#share .download_boxs ul li {
    list-style: none;
    padding: 15px;
}

#share .download_boxs ul li a {
    width: 100%;
    max-width: 150px;
    display: block;
}

#share .download_boxs {
    padding: 50px 30px;
    text-align: center;
}

#content p {
	color: #707070;
	font-family: "Calibri";
	font-size: 18px;
	line-height: 20px;
}

#content .content-box h4::after {content: "\f0a4";	display: block;	position: absolute;	width: 18px;	height: 18px;	top: 0px;	left: 0;	font-family: 'fontawesome';	font-size: 20px;}

#content .content-box h4:first-child {margin-top: 0px !important;}

#content .content-box h4 {
	color: #000;
	font-size: 22px;
	line-height: 20px;
	font-family: "Calibri-Bold";
	margin-top: 30px;
	margin-bottom: 12px;
    position: relative;
    padding-left: 30px;
}
#content .content-box h5 {
	color: #000;
	font-size: 20px;
	line-height: 20px;
	font-family: "Calibri-Bold";
	margin-top: 30px;
	margin-bottom: 12px;
}
#content .info_content p {
	color: #000;
}
#content ul li {
	color: #707070;
	font-family: "Calibri";
	font-size: 18px;
	line-height: 20px;
	margin-bottom: 15px;
	list-style-type: none;
	padding-left: 10px;
	margin-left: 15px;
}

#content .content-block {
	padding: 60px 0;
}

#content .info_content {
	margin-top: 50px !important;
}



#comingsoon {
	text-align: center;
	padding: 160px 0;
}
#comingsoon h1 {
	font-size: 40px;
	color: #ab1f2f;
}

#msg .modal-body {
	text-align: center;
	padding: 20px !important;
}

#msg .modal-headers h4 {
	color: #8e9c6b;
	font-size: 20px;
	text-align: center;
}

#msg .modal-body .btn.btn-blue {
	width: 100px;
}


#filters_com ion-item {
	--padding-start: 0;
	--inner-padding-end: 0;
	--inner-border-width: 0 0 0 0 !important;
	padding: 3px 0;
	--min-height: auto !important;
	--border-width: 0;
    --background: #fff !important;
}



#disciple .disciple-box .disciple_list.page_list_boxs {
	min-height: 350px;
}
ion-list {
    background: #fff !important;
}
#filters_com ion-item ion-icon{
    color: #86926b !important;
    --background: #fff !important;
}

.alert-wrapper {
    width: 100% !important;
    text-align: center !important;
    max-width: 300px !important;
}
.alert-message {
    max-height: 266px;
    font-size: 18px !important;
    color: #000 !important;
}
.alert-button-group {
    padding: 15px 0px 30px 0px !important;
    margin: 0;
    display: flex !important;
    justify-content: center !important;
}
.alert-button-group button {
    border-radius: 5px !important;
    color: #FFF !important;
    background-color: #508398 !important;
    font-size: 16px !important;
    text-align: center !important;
    min-width: 100px;
}

.alert-button-inner {
    justify-content: center !important;
}

#disciple .form_header .user_img ion-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 22;
    opacity: 0;
}
#disciple .form_header .user_img img {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#disciple .form_header .badge_icon {
    position: relative;
    width: 105px;
    height: 100px;
    overflow: hidden;
}

ion-checkbox{
    --checkmark-color: white !important;
    --border-color-checked: #508398 !important;
    --background-checked: #508398 !important;


    --border-color: #508398 !important;
    --background: white !important;

  }
  






.switch_box ion-grid {
    margin: 0px;
    padding: 0px;
}

.switch_box ion-row {
    padding: 3px 10px;
    background-color: #eee;
    border-radius: 10px;
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.switch_box ion-grid ion-row ion-col {
    flex: 0 0;
    white-space: nowrap;
}

ion-toggle {
    padding: 10px 0px !important;
    --handle-background: red;
    --handle-background-checked: #508398;
    --background: #ccc;
    --background-checked: #ccc;
}



.oc-border {
    border: 1px solid green;
}
 
.oc-background {
    background-color: limegreen;
}
 
.oc-name {
    font-family: Cambria, Cochin, Georgia, Times, Times New Roman, serif;
    white-space: nowrap;
}
 
.oc-designation {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-style: italic;
    white-space: nowrap;
}
 
.oc-img {
    margin-left: -10px;
    width: 60px;
    height: 60px;
}

svg.svg-chart-container {
    background-color: #fff !important;
    --background: #fff !important;
    background: #fff !important;
}
// svg.svg-chart-container g.chart {
//     // transform: translate(200px, 50px);
//     // transform: translate(300,20) scale(0.7) !important;
//     transform: translate(300px,20px) scale(1) !important;

// }
// svg.svg-chart-container g.chart rect {
//     // fill: url(#img-fit-icon);
//     fill: url(#img-fit-icon);
// }


#donate .language_box {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
}

#donate .language_box ion-radio {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 11;
    left: 0;
    top: 0;
    border: none;
    opacity: 0;
    cursor: pointer;
}

#donate .language_box .checkmark  {
    border: 2px solid #508398;
    width: 100%;
    height: 100%;
    background: #eee;
    padding: 10px;
    text-align: center;
    border-radius: 55px;
}

#donate .language_box ion-radio.radio-checked~.checkmark  {
    background: #508398 !important;  
}

#donate .resource-form ion-row {
    margin: 0px -10px !important;
}

#donate .resource-form ion-row ion-col {
    padding: 0px 10px !important;
}
table.table td ul li {
    list-style-type: disc;
    text-align: left;
    margin-bottom: 10px;
    font-size: 16px;
    color: #000;
}
table.table td ul li:last-child {
    margin-bottom: 0px;
}
#donate .language_box ion-radio.radio-checked ~ .checkmark ion-label {
    color: #fff !important;
}





/* Remove default bullets */
ul, #myUL {
    list-style-type: none;
  }
  
  /* Remove margins and padding from the parent ul */
  #myUL {
    margin: 0px !important;
    margin-left: 20px !important;
}
  
  
  /* Style the caret/arrow */
  .caret {
    cursor: pointer;
    user-select: none; /* Prevent text selection */
    font-size: 21px !important;

  }
  
  .caret::before {
	content: "＋";
	color: #ac2030;
	display: block;
	font-size: 21px !important;
	line-height: 15px;
}
  
  .caret.caret-down::before {
    content: "\FF0D";
  }
  
  /* Hide the nested list */
  .nested {
	display: none;
	margin-left: 40px !important;
}
  
  /* Show the nested list when the user clicks on the caret/arrow (with JavaScript) */
  .active {
    display: inline-block;
  }
  #myUL li {
    color: black;
    font-size: 18px !important;
    text-align: center;
}


.node-rect {
	stroke: #508398 !important;
	fill: #508398 !important;
    height: 82px;
    width: 25%;
}

g.chart path.link {
	stroke: #508398 !important;
}


g.chart .title, g.chart .description {
    fill: #fff !important;
    font-size: 19px !important;
    font-weight: bold !important;
}

text.description[refField="nodeId"] {
    display: none !important;
}

g.toolbar rect {
    fill: #508398 !important;
    stroke: #ac2030 !important;
    stroke-width: 5;
    fill-opacity: 0.9;
    stroke-opacity: 0.9;
}

#myUL {
	margin: 0px !important;
	padding: 0px !important;
}

#myUL .list_content {
	display: flex;
	align-items: center;
	padding: 3px 0;
}

#myUL .list_content .caret {
	display: block;
	width: 22px;
	height: 15px;
	position: relative !important;
	cursor: pointer !important;
	font-size: 20px !important;
	line-height: normal !important;
}
#myUL .list_content .content {
	color: #508398;
	font-size: 18px;
	display: block;
}

#myUL .list_content .content.single {
        color: #000;
        margin-left: 23px !important;
        // display: list-item;
        // list-style-type: square;
}
#myUL .list_content .content.single::before {
    content: "■"; color: #ac2030;
    display: inline-block;
    margin: 4px;
}



.form-group.fileuploads {
    border: 1px solid #d5d5d5;
    color: #000 !important;
    font-size: 16px !important;
    border-radius: 5px;
    padding: 14px 13px !important;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
}
.form-group.fileuploads .file_input {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 11;
    opacity: 0;
}

.form-group.fileuploads ion-label {
    color: #000 !important;
    font-size: 15px !important;
    border-radius: 5px;
    opacity: 0.5;
    padding: 0px !important;
}

.form-group.fileuploads .file_input {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 11;
    opacity: 0;
}

.form-group.fileuploads .menu-img {
    padding: 0px !important;
    margin: 0px !important;
    flex: 0 0 24px;
    max-width: 24px;
}



#login .form-group {
    position: relative;
}

#login .form-group i.pass {
    position: absolute;
    bottom: 21px;
    z-index: 111;
    font-size: 17px;
    color: #508398 !important;
    right: 15px;
    cursor: pointer;
    visibility: visible;
    opacity: 1;
    display: block;
}
ion-popover {
    --width: 550px  !important;
  }
  

#myUL > li > ul {
	margin: 0 auto !important;
	// max-width: 280px;
    text-align: left;
}
ion-popover.pop {
    --width: 300px !important;
}

#nav li#donate {
    flex: 0 0 200px;
    max-width: 200px;
}
#donate ion-row {
    margin: 0px -5px !important;
    align-items: center !important;
}
#donate ion-row ion-col {
    padding: 0px  5px!important;
}
#donate .language_box .checkmark {
    padding: 5px !important;
}
#donate .language_box ion-label {
    font-size: 16px !important;
    display: block;
    text-align: center;
    line-height: 20px;
    padding-top: 2px;
}

.animate {
    animation: tada both 3s;
}

.animatee {
    animation: bounce both 3s;
}



.fa-shake {
    -webkit-animation-name:fa-shake;
    animation-name:fa-shake;
    -webkit-animation-duration:var(--fa-animation-duration,2s);
    animation-duration:var(--fa-animation-duration,2s);
    -webkit-animation-iteration-count:var(--fa-animation-iteration-count,150s);
    animation-iteration-count:var(--fa-animation-iteration-count,150s);
    -webkit-animation-timing-function:var(--fa-animation-timing,linear);
    animation-timing-function:var(--fa-animation-timing,linear)
   }
  
   

   
   @-webkit-keyframes fa-shake {
    0% {
     -webkit-transform:rotate(-15deg);
     transform:rotate(-15deg)
    }
    4% {
     -webkit-transform:rotate(15deg);
     transform:rotate(15deg)
    }
    8%,
    24% {
     -webkit-transform:rotate(-18deg);
     transform:rotate(-18deg)
    }
    12%,
    28% {
     -webkit-transform:rotate(18deg);
     transform:rotate(18deg)
    }
    16% {
     -webkit-transform:rotate(-22deg);
     transform:rotate(-22deg)
    }
    20% {
     -webkit-transform:rotate(22deg);
     transform:rotate(22deg)
    }
    32% {
     -webkit-transform:rotate(-12deg);
     transform:rotate(-12deg)
    }
    36% {
     -webkit-transform:rotate(12deg);
     transform:rotate(12deg)
    }
    40%,
    to {
     -webkit-transform:rotate(0deg);
     transform:rotate(0deg)
    }
   }
   @keyframes fa-shake {
    0% {
     -webkit-transform:rotate(-15deg);
     transform:rotate(-15deg)
    }
    4% {
     -webkit-transform:rotate(15deg);
     transform:rotate(15deg)
    }
    8%,
    24% {
     -webkit-transform:rotate(-18deg);
     transform:rotate(-18deg)
    }
    12%,
    28% {
     -webkit-transform:rotate(18deg);
     transform:rotate(18deg)
    }
    16% {
     -webkit-transform:rotate(-22deg);
     transform:rotate(-22deg)
    }
    20% {
     -webkit-transform:rotate(22deg);
     transform:rotate(22deg)
    }
    32% {
     -webkit-transform:rotate(-12deg);
     transform:rotate(-12deg)
    }
    36% {
     -webkit-transform:rotate(12deg);
     transform:rotate(12deg)
    }
    40%,
    to {
     -webkit-transform:rotate(0deg);
     transform:rotate(0deg)
    }
   }
   .edit_icon {
    color: #fff;
    position: absolute;
    width: 30px;
    height: 30px;
    background: #518398;
    border-radius: 50%;
    bottom: 10px;
    right: 10px;
}

.edit_icon i {
    display: block;
    line-height: 30px;
    font-size: 16px;
    text-align: center;
    color: #fff;
}

#pagebanner .pagebanner-block {
    display: flex;
    position: relative;
    height: calc(100vh - 80px);
    align-items: center;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

#pagebanner .pagebanner-block h1 {
    font-size: 50px;
    text-shadow: 0px 0px 4px #000;
}
.info{
    --background: #508398;
     color: white;
     font-size: 16px !important;
}
ion-card {
    background: #fff !important;
}
.PayPal-Sel-modal ion-content {
    border-radius: 20px 20px 20px 20px;
    overflow: hidden;
}
.PayPal-Sel-modal #organization  .modal-dialog {
    margin: 15px auto auto auto;
}


.input-error {
    border: #ac2030 1px solid !important;
}
.error_form{
    color: #ac2030; 
    padding-top: 0.2rem;
    font-size: 14px !important;
}
.chk{
    color: red !important;
    --border-color: #ac2030 !important;
}

.flex_center{
    display: flex;
    align-items: center;
    justify-content: center;
}
.flex_left{
    display: flex;
    align-items: center;
    justify-content: left;
}
.flex_even{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.flex_right{
    display: flex;
    align-items: center;
    justify-content: right;
}


// *****************  for language docx **************************

.bk-btn {
    height: 35px;
    width: 77px;
    margin-inline: 8px;
    font-size: 12px;
  }

  #language_res ion-select {
    --padding-start: 0px;
    background-color: #fff !important;
    border-radius: 5px !important;
    height: 50px !important;
    color: #707070 !important;
    box-shadow: none !important;
    font-weight: 400;
    width: 60%;
    padding: 12px 15px !important;
    border: 1px solid #a09898 !important;
    font-family: "Calibri";
    font-size: 15px !important;
  }

  #lang_banner #banner{
    position: fixed;
    width: 100%;
    z-index: 2650;
  }
  #lang_banner #banner .banner-block {
    padding: 5px 0px;
    width: 100%;
    position: relative;
    background: #508398 !important;
    height: 80px;
}
// *****************  for language docx **************************
@media only screen and (max-width: 768px) {
.change-success-modal {
	--height: 85% !important; 
	align-items: center !important;
	background: rgba(0, 0, 0, 0.7);
	--width: 88%;
	padding: 0;
	--background: transparent !important;
}
#pagebanner .slider-content-box h4 {
    color: #fff;
    font-size: 25px !important;
    margin-bottom: 15px;
    text-transform: uppercase;
    max-width: 55%;
    line-height: 70px;
}
.pagebanner-blocks {
    height: 211px !important;
}
.pagebanner-blocks img{
    height: 100% !important;
}
.ev_row {
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.188);
    border-radius: 5px;
    margin-inline: 8px;
    padding: 12px !important;
    margin-block: 10px !important;
}
}
#date ion-datetime{
    min-width: max-content;
}
#date ion-datetime .datetime-calendar{
    width: 1200px !important;
}

.input-label-placement-start.sc-ion-input-md-h .input-wrapper.sc-ion-input-md {
   display: flex;
   align-items: center;
}
.sc-ion-input-md-h:not(.legacy-input) {
    min-height: 56px;
}


ion-select::part(placeholder),
ion-select::part(text) {
  flex: 0 0 100%;
}
.ttl{
    text-align: center;margin-top: 30%;font-size: 22px;color:#518398;
}



#filters_com_overly.open {
    z-index: 1111;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.8);
}

.fancybox-container {
    min-height: 100vh !important;
}